import { isNotEmptyString } from '@racemap/utilities/functions/utils';
import { type FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { EventMessageVisibility } from './EventMessageVisibility';

interface Props {
  value: string;
  oldEventName: string;
  onChange: (newValue: string) => void;
}

const hint = 'Please give the new Tracking Map a new name. Needs to be different from original.';

export const NameFieldGroup: FC<Props> = ({ value, onChange, oldEventName }) => {
  const [nameEventError, setNameEventError] = useState('');
  const hasError = isNotEmptyString(nameEventError);
  useEffect(() => {}, [value]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const errorMessage = validate(e.target.value, oldEventName);
    setNameEventError(errorMessage);
    onChange(e.target.value);
  };

  return (
    <>
      <Form.Label>New Event Name*</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control value={value} onChange={handleNameChange} />
        <EventMessageVisibility
          message={nameEventError || hint}
          variant={hasError ? 'error' : 'info'}
        />
      </InputGroup>
    </>
  );
};

export const validate = (value: string, oldEventName: string): string => {
  if (oldEventName === value) {
    return 'The name of your new Tracking Map alraedy exist. Please choose another name.';
  }
  if (value === '') {
    return 'Name is required';
  }
  return '';
};
