import styled from '@emotion/styled';
import { MaxMobileWindowWidth } from '@racemap/utilities/consts/common';
import TestSelectors from '@racemap/utilities/consts/test_selectors';
import { DateTime } from 'luxon';
import type { FC } from 'react';
import { EventDuplicateModal } from 'src/components/EventDuplicateModal';
import { useCurrentEvent, useCurrentUser } from '../../../lib/customHooks';
import { EventLogo } from '../../BasicComponents/EventLogo';
import { HStack } from '../../BasicComponents/MetaComponent';
import { SupportSearchBar } from '../../SupportSearchBar';
import { Buttons } from './Buttons';
import { EventMetaInfos } from './EventInfos';
import { PriceField } from './PriceField';

export const TitleBar: FC = () => {
  const event = useCurrentEvent();
  const user = useCurrentUser();
  if (event == null || user == null) return <></>;

  const eventEndTime = DateTime.fromISO(event.endTime);
  const twoWeeksAgo = DateTime.now().minus({ weeks: 2 });
  const isEventFinishedForTwoWeeks = eventEndTime < twoWeeksAgo;

  return (
    <Container id={TestSelectors.admin.eventEditor.titleBar.container.toString()}>
      <EventInfos>
        <LogoAndMeta>
          <EventLogo event={event} size={45} />
          <EventMetaInfos event={event} />
        </LogoAndMeta>

        <SupportSearchBar />
      </EventInfos>
      <PriceAndButtons>
        <PriceField event={event} />
        <SupportSearchBar reactOnShortcut={false} />
        <Buttons event={event} />
        {isEventFinishedForTwoWeeks && <EventDuplicateModal event={event} />}
      </PriceAndButtons>
    </Container>
  );
};

const Container = styled(HStack)`
  position: fixed;
  top: 71px;
  left: 0px;
  right: 0px;
  flex-direction: row;
  background-color: white;
  border-bottom: 1px solid #e7e7e7;
  margin-top: -20px;
  padding: 3px 10px;
  z-index: 1;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    position: relative;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    top: 0;
    margin-top: unset;
    padding: unset;
  }
`;

const EventInfos = styled.div`
  display: flex;
  align-items: center;

  .support-search-bar {
      display: none;
  }

  @media (max-width: ${MaxMobileWindowWidth}px) {
    position: fixed;
    top: 50px;
    right: 55px;
    left: 0;
    border-bottom: solid 1px #e7e7e7;
    padding: 5px;
    background: white;
    z-index: 110;
    flex: 1;
    justify-content: space-between;
    align-items: center;

    & > .support-search-bar {
      display: block;
    }
  }
`;

const LogoAndMeta = styled(HStack)`
  flex: 1;
  justify-content: flex-start;
`;

const PriceAndButtons = styled.div`
  display: contents;
  background: white;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: ${MaxMobileWindowWidth}px) {
    position: relative;
    margin-top: 56px;
    width: 100%;
    padding: 5px;
    gap: 5px;
    justify-content: space-between;

    .support-search-bar {
      display: none;
    }
  }
`;
