export enum Platforms {
  ANDROID = 'Android',
  IOS = 'IOS',
  UNKNOWN = 'UNKNOWN',
  MAC = 'Mac',
  WINDOWS = 'Windows',
  LINUX = 'Linux',
}

/**
 * Determines the platform based on the provided user agent string.
 *
 * @param userAgent - The user agent string from the browser or device
 * @returns A string representing the platform identifier from the Platforms enum
 *
 * @example
 * ```typescript
 * const platform = getPlatformOfUserAgent(navigator.userAgent);
 * // Returns 'ANDROID' for Android devices
 * // Returns 'IOS' for iOS devices
 * // Returns 'MAC' for MacOS
 * // Returns 'WINDOWS' for Windows
 * // Returns 'LINUX' for Linux
 * // Returns 'UNKNOWN' for unrecognized platforms
 * ```
 */
export const getPlatformOfUserAgent = (userAgent: string): Platforms => {
  if (userAgent.match(/Android/i)) {
    return Platforms.ANDROID;
  }
  if (userAgent.match(/iPhone|iPad|iPod/i)) {
    return Platforms.IOS;
  }
  if (userAgent.match(/Mac/i)) {
    return Platforms.MAC;
  }
  if (userAgent.match(/Windows/i)) {
    return Platforms.WINDOWS;
  }

  if (userAgent.match(/Linux/i)) {
    return Platforms.LINUX;
  }
  return Platforms.UNKNOWN;
};

/**
 * Determines the browser type based on the provided user agent string.
 *
 * @param userAgent - The user agent string to analyze
 * @returns The identified browser name ('Firefox', 'Chrome', 'Safari', 'Edge', or 'Unknown')
 *
 * @example
 * ```typescript
 * const browser = getBrowserOfUserAgent(navigator.userAgent);
 * console.log(browser); // 'Chrome'
 * ```
 */
export const getBrowserOfUserAgent = (userAgent: string): string => {
  if (userAgent.match(/Firefox/i)) {
    return 'Firefox';
  }
  if (userAgent.match(/Chrome/i)) {
    return 'Chrome';
  }
  if (userAgent.match(/Safari/i)) {
    return 'Safari';
  }
  if (userAgent.match(/Edge/i)) {
    return 'Edge';
  }
  return 'Unknown';
};

/**
 * Determines if the user agent string indicates a mobile device.
 *
 * @param userAgent - The user agent string from the browser
 * @returns True if the device is mobile (Android, iPhone, iPad, or iPod), false otherwise
 *
 * @example
 * ```ts
 * const isMobile = getIsMobile(navigator.userAgent);
 * if (isMobile) {
 *   // Handle mobile specific logic
 * }
 * ```
 */
export const getIsMobile = (userAgent: string): boolean => {
  return userAgent.match(/Android|iPhone|iPad|iPod/i) != null;
};
