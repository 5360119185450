import type { DateTime } from 'luxon';

export const calculateDisabledDate = ({
  current,
  minDate,
  maxDate,
}: { current: DateTime; minDate?: DateTime; maxDate?: DateTime }) => {
  if (minDate == null && maxDate == null) return false;

  if (minDate && current < minDate.startOf('day')) {
    return true;
  }
  if (maxDate && current > maxDate.endOf('day')) {
    return true;
  }
  return false;
};
