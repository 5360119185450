import { type ReactElement, useEffect } from 'react';
import { useStore } from '../../store/reducers';

type Props = {
  children: ReactElement;
};

export default function UserContext({ children }: Props) {
  const isLoading = useStore((s) => s.users.isLoadingCurrentUser);
  const { loadCurrentUser } = useStore((s) => s.users.actions);
  const { loadBrands } = useStore((s) => s.brands.actions);

  useEffect(() => {
    (async () => {
      await loadCurrentUser();
      await loadBrands();
    })();
  }, []);

  if (isLoading) {
    return <div />;
  }
  return children;
}
