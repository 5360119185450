import styled from '@emotion/styled';
import { Space } from 'antd';
import { type FC, useCallback } from 'react';
import { usePlatform } from 'src/lib/customHooks';
import { Platforms } from 'src/lib/userAgent';
import { useEventListener } from 'usehooks-ts';

interface Props {
  keyValue: string;
  label?: string;
  needMeta?: boolean;
  needShift?: boolean;
  needCtrl?: boolean;
  size?: 'small' | 'large';
  bordered?: boolean;
  withPlus?: boolean;
  onTrigger?: () => void;
}

export const Hotkeys: FC<Props> = ({
  keyValue,
  label,
  needMeta = false,
  needShift = false,
  needCtrl = false,
  size = 'large',
  bordered = true,
  withPlus = false,
  onTrigger,
}) => {
  const platform = usePlatform();

  if (!needMeta && !needShift && !needCtrl) {
    throw new Error('At least one modifier key must be set');
  }

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (onTrigger == null || e.key !== keyValue) return;
      if (needMeta && !e.metaKey) return;
      if (needShift && !e.shiftKey) return;
      if (needCtrl && !e.ctrlKey) return;
      e.preventDefault();
      e.stopPropagation();

      onTrigger?.();
    },
    [onTrigger, keyValue, needMeta, needShift, needCtrl],
  );

  useEventListener('keydown', handleKeyDown);

  if (platform === Platforms.ANDROID || platform === Platforms.IOS) return null;

  return (
    <Container size={0} bordered={bordered} sizeType={size}>
      {needMeta && <HotKey size={size}>⌘</HotKey>}
      {needMeta && withPlus && <Plus>+</Plus>}
      {needShift && <HotKey size={size}>⇧</HotKey>}
      {needShift && withPlus && <Plus>+</Plus>}
      {needCtrl && <HotKey size={size}>Ctrl</HotKey>}
      {needCtrl && withPlus && <Plus>+</Plus>}
      <HotKey size={size}>{label || keyValue}</HotKey>
    </Container>
  );
};

const Container = styled(Space)<{ bordered: boolean; sizeType: 'large' | 'small' }>`
    display: flex;
    padding: 2px;
    border-radius: 5px;
    background-color: ${({ bordered }) => (bordered ? '#00000033' : 'none')};
    height: ${({ sizeType }) => (sizeType === 'large' ? '23px' : '18px')};
`;

const HotKey = styled.div<{ size: 'small' | 'large' }>` 
    color: white;
    height: ${({ size }) => (size === 'large' ? '23px' : '18px')};
    font-size: ${({ size }) => (size === 'large' ? '12px' : '10px')};
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${({ size }) => (size === 'large' ? '12px' : '10px')};
    padding: 3px;
`;

const Plus = styled.div`
    color: white;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    width: 10px;
`;
