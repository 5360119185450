import { RacemapV2APIClient } from '@racemap/sdk';
import type { ObjectId } from '@racemap/sdk/schema/base';
import type {
  MessageTemplate,
  MessageTemplatePrototype,
} from '@racemap/sdk/schema/messageTemplates';
import { produce } from 'immer';
import type { StoreApi } from 'zustand';
import type { DraftState, State } from './reducers';

export interface MessageTemplatesState {
  messageTemplates: {
    items: Map<string, MessageTemplate>;
    isLoading: boolean;
    isSaving: boolean;
    impersonatedUserId: ObjectId | null;
    actions: {
      loadData: () => Promise<void>;
      createTemplate: (template: MessageTemplatePrototype) => Promise<MessageTemplate>;
      getTemplate: (templateId: ObjectId) => Promise<MessageTemplate | null>;
      updateTemplate: (
        templateId: ObjectId,
        template: Partial<MessageTemplate | MessageTemplatePrototype>,
      ) => Promise<void>;
      removeTemplate: (templateId: ObjectId) => Promise<void>;
      setImpersonatedUserId: (userId: ObjectId | null) => void;
    };
  };
}

const apiClient = RacemapV2APIClient.fromWindowLocation();

export const createMessageTemplatesStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): MessageTemplatesState => ({
  messageTemplates: {
    items: new Map(),
    isLoading: false,
    isSaving: false,
    impersonatedUserId: null,
    actions: {
      loadData: async () => {
        set(
          produce((s: DraftState) => {
            s.messageTemplates.isLoading = true;
          }),
        );

        const targetUserId = get().messageTemplates.impersonatedUserId?.toHexString();
        const messageTemplates = await apiClient.listMessageTemplates({
          queries: { userId: targetUserId },
        });

        set(
          produce((s) => {
            s.messageTemplates.isLoading = false;
            s.messageTemplates.items = new Map(
              messageTemplates.map((messageTemplate) => [
                messageTemplate.id.toHexString(),
                messageTemplate,
              ]),
            );
          }),
        );
      },
      getTemplate: async (templateId: ObjectId) => {
        set(
          produce((s: DraftState) => {
            s.messageTemplates.isLoading = true;
          }),
        );
        try {
          const messageTemplate = await apiClient.getMessageTemplate({
            params: { messageTemplateId: templateId.toHexString() },
          });

          if (messageTemplate.subTemplates.length > 0) {
            const subTemplates = await Promise.all(
              messageTemplate.subTemplates.map((id) =>
                apiClient.getMessageTemplate({ params: { messageTemplateId: id.toHexString() } }),
              ),
            );

            subTemplates.forEach((subTemplate) => {
              set(
                produce((s) => {
                  s.messageTemplates.items.set(subTemplate.id.toHexString(), subTemplate);
                }),
              );
            });
          }

          set(
            produce((s) => {
              s.messageTemplates.isLoading = false;
              s.messageTemplates.items.set(messageTemplate.id.toHexString(), messageTemplate);
            }),
          );

          return messageTemplate;
        } catch {
          set(
            produce((s: DraftState) => {
              s.messageTemplates.isLoading = false;
            }),
          );
          return null;
        }
      },
      createTemplate: async (template: MessageTemplatePrototype) => {
        try {
          set(
            produce((s: DraftState) => {
              s.messageTemplates.isSaving = true;
            }),
          );

          const createdTemplate = await apiClient.createMessageTemplate(template);

          set(
            produce((s) => {
              s.messageTemplates.isSaving = false;
              s.messageTemplates.items.set(createdTemplate.id.toHexString(), createdTemplate);
            }),
          );
          return createdTemplate;
        } catch (e) {
          set(
            produce((s: DraftState) => {
              s.messageTemplates.isSaving = false;
            }),
          );
          throw e;
        }
      },
      updateTemplate: async (
        templateId: ObjectId,
        template: Partial<MessageTemplate | MessageTemplatePrototype>,
      ) => {
        const updatedTemplate = await apiClient.updateMessageTemplate(template, {
          params: { messageTemplateId: templateId.toHexString() },
        });

        set(
          produce((s) => {
            s.messageTemplates.items.set(updatedTemplate.id.toHexString(), updatedTemplate);
          }),
        );
      },
      removeTemplate: async (templateId: ObjectId) => {
        await apiClient.deleteMessageTemplate(undefined, {
          params: { messageTemplateId: templateId.toHexString() },
        });

        set(
          produce((s) => {
            s.messageTemplates.items.delete(templateId.toHexString());
          }),
        );
      },
      setImpersonatedUserId: (userId: ObjectId | null) => {
        set(
          produce((s) => {
            s.messageTemplates.impersonatedUserId = userId;
          }),
        );
        get().messageTemplates.actions.loadData();
      },
    },
  },
});
