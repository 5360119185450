import { isEmptyString } from '../functions/utils';
export { SportTypes } from '@racemap/sdk/schema/events';
import { type EventType, SportTypes } from '@racemap/sdk/schema/events';

// different sport types with icons and information to import

// Also see /services/rust-points/src/racemap_event_types.rs
const eventTypes: Array<EventType> = [
  {
    id: SportTypes.RUNNING,
    name: 'Running',
    description: 'test description',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/running.svg',
    defaultSpeed: 15, // unit km/h
    maxSpeed: 35, // unit km/h
    minSpeed: 5, // unit km/h
    onlySplit: false, // the type is only for splits choosable
  },
  {
    id: SportTypes.TRAIL,
    name: 'Trail',
    description: 'test description trail',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/trail.svg',
    defaultSpeed: 15,
    maxSpeed: 35,
    minSpeed: 3,
    onlySplit: false,
  },
  {
    id: SportTypes.BIKING,
    name: 'Biking',
    description: 'test description biking',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/biking.svg',
    defaultSpeed: 30,
    maxSpeed: 70,
    minSpeed: 5,
    onlySplit: false,
  },
  {
    id: SportTypes.MTB,
    name: 'MTB',
    description: 'test description mtb',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/mtb.svg',
    defaultSpeed: 30,
    maxSpeed: 70,
    minSpeed: 5,
    onlySplit: false,
  },
  {
    id: SportTypes.INLINE_SKATING,
    name: 'Inline Skating',
    description: 'test description inline-skating',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/inline-skating.svg',
    defaultSpeed: 25,
    maxSpeed: 50,
    minSpeed: 0.1,
    onlySplit: false,
  },
  {
    id: SportTypes.SWIMMING,
    name: 'Swimming',
    description: 'test description swimming',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/swimming.svg',
    defaultSpeed: 4,
    maxSpeed: 8,
    minSpeed: 0.1,
    onlySplit: false,
  },
  {
    id: SportTypes.TRIATHLON,
    name: 'Triathlon',
    description: 'test description triathlon',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/triathlon.svg',
    defaultSpeed: 15,
    maxSpeed: 50,
    minSpeed: 0.1,
    onlySplit: false,
  },
  {
    id: SportTypes.EQUESTRIAN_SPORT,
    name: 'Equestrian Sport',
    description: 'test description equestrian',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/equestrian.svg',
    defaultSpeed: 20,
    maxSpeed: 50,
    minSpeed: 5,
    onlySplit: false,
  },
  {
    id: SportTypes.ORIENTEERING,
    name: 'Orienteering',
    description: 'test description orienteering',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/orienteering.svg',
    defaultSpeed: 17,
    maxSpeed: 25,
    minSpeed: 2,
    onlySplit: false,
  },
  {
    id: SportTypes.ROWING,
    name: 'Rowing',
    description: 'test description rowing',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/rowing.svg',
    defaultSpeed: 17,
    maxSpeed: 25,
    minSpeed: 2,
    onlySplit: false,
  },
  {
    id: SportTypes.SAILING,
    name: 'Sailing',
    description: 'test description sailing',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/sailing.svg',
    defaultSpeed: 20,
    maxSpeed: 50,
    minSpeed: 0.1,
    onlySplit: false,
  },
  {
    id: SportTypes.CANOEING,
    name: 'Canoeing',
    description: 'test description canoeing',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/canoeing.svg',
    defaultSpeed: 20,
    maxSpeed: 35,
    minSpeed: 5,
    onlySplit: false,
  },
  {
    id: SportTypes.MOTORBOAT,
    name: 'Motorboat',
    description: 'test description motorboat',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/motorboat.svg',
    defaultSpeed: 50,
    maxSpeed: 150,
    minSpeed: 5,
    onlySplit: false,
  },
  {
    id: SportTypes.SUP,
    name: 'SUP',
    description: 'test description sup',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/sup.svg',
    defaultSpeed: 8,
    maxSpeed: 20,
    minSpeed: 0.1,
    onlySplit: false,
  },
  {
    id: SportTypes.SKIING,
    name: 'Skiing',
    description: 'test description skiing',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/skiing.svg',
    defaultSpeed: 30,
    maxSpeed: 80,
    minSpeed: 10,
    onlySplit: false,
  },
  {
    id: SportTypes.MOTOCROSS,
    name: 'Motocross',
    description: 'test description motocross',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/motocross.svg',
    defaultSpeed: 120,
    maxSpeed: 200,
    minSpeed: 30,
    onlySplit: false,
  },
  {
    id: SportTypes.RALLY_CAR,
    name: 'Rally Car',
    description: 'test description rally-aar',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/rally-car.svg',
    defaultSpeed: 180,
    maxSpeed: 300,
    minSpeed: 30,
    onlySplit: false,
  },
  {
    id: SportTypes.HIKING,
    name: 'Hiking',
    description: 'test description hiking',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/hiking.svg',
    defaultSpeed: 5,
    maxSpeed: 25,
    minSpeed: 1,
    onlySplit: false,
  },
  {
    id: SportTypes.DUATHLON,
    name: 'Duathlon',
    description: 'test description duathlon',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/duathlon.svg',
    defaultSpeed: 20,
    maxSpeed: 50,
    minSpeed: 3,
    onlySplit: false,
  },
  {
    id: SportTypes.TRANSITION_AREA,
    name: 'Transition area',
    description: 'test description transition area',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/triathlon.svg',
    defaultSpeed: 1.0,
    maxSpeed: 100,
    minSpeed: 0.1,
    onlySplit: true,
  },
  {
    id: SportTypes.OTHER,
    name: 'Other',
    description: 'test description other',
    symbol: 'https://static-cdn.racemap.com/img/event-icons/other.svg',
    onlySplit: false,
    defaultSpeed: 12,
    maxSpeed: 100,
    minSpeed: 0.1,
  },
];

export const eventTypesByType = Object.fromEntries(eventTypes.map((eT) => [eT.id, eT])) as Record<
  SportTypes,
  EventType
>;

export const isEventSportType = (type: string | null): type is SportTypes => {
  if (type === null || isEmptyString(type)) return false;
  return Object.keys(eventTypesByType).includes(type);
};

export default eventTypes;
