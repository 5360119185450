import styled from '@emotion/styled';
import { Skeleton } from 'antd';
import type { FC } from 'react';
import { staticThemeToken } from '../../../lib/theme';
import { HStack } from '../MetaComponent';

interface Props {
  currentRange: 'start' | 'end' | null;
}

export const ExtraPanelHeader: FC<Props> = ({ currentRange }) => {
  let content = null;

  switch (currentRange) {
    case 'start':
      content = 'Modify Start Time';
      break;
    case 'end':
      content = 'Modify End Time';
      break;
    default:
      content = <Skeleton.Input size="small" style={{ width: 150 }} />;
  }

  return <Container>{content}</Container>;
};

const Container = styled(HStack)`
    align-items: center;
    justify-content: flex-start;
    padding: 5px 11px;
    line-height: 24px;
    font-weight: ${staticThemeToken.fontWeightStrong};
    border-bottom: 1px solid ${staticThemeToken.colorBorder};
`;
