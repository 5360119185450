import styled from '@emotion/styled';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { Images } from '@racemap/utilities/types/events';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import { NativeTypes } from 'react-dnd-html5-backend';
import { DropZone as DropZoneRaw } from '../../DropZone';
import { IconFail, IconOk, IconSpinner, IconTrash, IconUpload } from '../../Icon';
import { Center } from '../MetaComponent';

const apiClient = RacemapAPIClient.fromWindowLocation();

interface Props {
  id?: string;
  value: Images | null;
  withOutPlaceholder?: boolean | null;
  width?: number;
  height?: number;
  imageClass: 'logo' | 'banner' | 'sponsorLogo' | 'poiIcon';
  onChange: (arg0: Images | null) => Promise<void>;
}

export const ImageDropView: FC<Props> = ({
  value,
  onChange,
  id,
  withOutPlaceholder = false,
  height,
  width,
  imageClass,
}) => {
  const fileInputRef = useRef<null | HTMLInputElement>(null);
  const [status, setStatus] = useState<STATUS | null>(null);
  const [statusText, statusIcon] = getStatusIndicator(status);
  const imageUrl = getImageUrl(value);

  function handleFilesDrop(files: FileList | null) {
    if (files != null && files.length > 0) {
      const file = files[0];
      setStatus(STATUS.UPLOADING);

      apiClient
        .uploadEventImage({ [imageClass]: file })
        .then((imagesObj) => {
          setStatus(STATUS.SUCCESS);
          onChange(imagesObj);
        })
        .catch(() => {
          setStatus(STATUS.FAILURE);
        });
    }
  }

  function handleDropTargetClick() {
    const inputField = fileInputRef.current;
    if (inputField != null) {
      inputField.click();
    }
  }

  function handleRemoveClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    onChange(null);
  }

  return (
    <DropZone
      onFilesDrop={handleFilesDrop}
      uploaded={value != null}
      showOverlay={false}
      acceptTargetTypes={[NativeTypes.FILE]}
      height={height}
      width={width}
    >
      <Target onClick={handleDropTargetClick} height={height} width={width}>
        {imageUrl ? (
          <div style={{ position: 'relative' }}>
            <RemoveImage onClick={handleRemoveClick}>
              <IconTrash className="pointer" fixedWidth />
            </RemoveImage>
            <img src={imageUrl} alt="" />
          </div>
        ) : (
          <UploadField className="upload-field">
            <IconContainer>{statusIcon}</IconContainer>
            {(!withOutPlaceholder || status != null) && <div>{statusText}</div>}
            <input
              type="file"
              className="upload-image"
              accept="image/*"
              multiple={false}
              style={{ display: 'none' }}
              id={id}
              ref={fileInputRef}
              onChange={({ target }) => handleFilesDrop(target.files)}
            />
          </UploadField>
        )}
      </Target>
    </DropZone>
  );
};

enum STATUS {
  DEFAULT = 'DEFAULT',
  UPLOADING = 'UPLOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

function getImageUrl(images: Images | null): string | null {
  if (images == null) return null;
  const lowRes = images.app || images.property || images.small;
  if (lowRes != null) return lowRes;
  const highRes = images.medium || images.middle || images.large;
  if (highRes != null) return highRes;
  return images.original || null;
}

function getStatusIndicator(status: STATUS | null): [string, React.ReactElement] {
  switch (status) {
    case STATUS.UPLOADING:
      return ['Processing...', <IconSpinner key="sync-alt" />];
    case STATUS.SUCCESS:
      return ['Success', <IconOk key="check" />];
    case STATUS.FAILURE:
      return ['Failed', <IconFail key="times" />];
    default:
      return ['Upload Image...', <IconUpload key="cloud-upload-alt" />];
  }
}

const DropZone = styled(DropZoneRaw)<{ uploaded: boolean; height?: number; width?: number }>`
  border: ${({ uploaded }) =>
    uploaded ? `3px solid ${RacemapColors.LightGray}` : `3px dashed ${RacemapColors.LightGray}`};
  border-radius: 10px;
  cursor: pointer;
  width: auto;
  color: ${RacemapColors.LightGray};

  img:hover {
    filter: brightness(0.8);
  }

  &.file-hover {
    border: 3px solid ${RacemapColors.BaseGreen};

    .upload-field {
      color: ${RacemapColors.BaseGreen};
    }
  }
`;

const Target = styled(Center)<{ height?: number; width?: number }>`
  width: ${({ width = 128 }) => width}px;
  height: ${({ height = 128 }) => height}px;

  img {
    width: ${({ width = 128 }) => width}px;
    height: ${({ height = 128 }) => height}px;
    border-radius: 8px;
    object-fit: fill;
  }

  .upload-button {
    color: ${RacemapColors.DarkGray};
    font-size: 20px;
  }
`;

const RemoveImage = styled.div`
  color: #b4b6b9;
  background-color: white;
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 2px;
  border-radius: 0.25rem;
  border: solid 1px ${RacemapColors.LightGray};
  z-index: 10;

  :hover {
    filter: brightness(0.8);
  }
`;

const IconContainer = styled.div`
  font-size: 50px;
`;

const UploadField = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgba(100, 100, 100, 0.533);
`;
