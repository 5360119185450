import { z } from 'zod';
import { EventTypeSchema, SportTypes } from '../schema/events';
import { DateSchema, ObjectIdSchema } from './base';
import { StarterSchema } from './starters';

const PredictiveParamsSchema = z.object({
  distanceFromTrack: z.number(),
  maxCompensationSpeed: z.number(),
  minDistance: z.number(),
  rssiThreshold: z.number(),
  futureTrackScaleValue: z.number(),
  lookBackDistance: z.number(),
  lookForwardTime: z.number(),
  jumpingDotThreshold: z.number(),
});

const ImageSchema = z.object({
  fileName: z.string().optional(),
  original: z.string().optional(),
  app: z.string().optional(),
  medium: z.string().optional(),
  large: z.string().optional(),
});

const ModulesSchema = z.object({
  predictive: z.object({
    params: PredictiveParamsSchema,
    enabled: z.boolean(),
    timingsystem: z.string(),
  }),
  sponsor: z.object({
    state: z.string(),
    logo: ImageSchema.nullable(),
    url: z.string(),
    title: z.string(),
  }),
  keys: z.object({ enabled: z.boolean() }),
  projection: z.object({ enabled: z.boolean() }),
  monitor: z.object({
    state: z.string(),
    alertTargetMails: z.array(z.record(z.string())),
  }),
  timing: z.object({
    leaderboard: z.object({
      type: z.string(),
      externalLeaderboardUrl: z.string(),
    }),
    teamTarget: z.object({
      enabled: z.boolean(),
      distance: z.number(),
      description: z.string(),
    }),
    visibleElements: z.object({
      activeDuration: z.boolean(),
      restDuration: z.boolean(),
      difference: z.boolean(),
      liveState: z.boolean(),
      currentProgress: z.boolean(),
      finish: z.boolean(),
      stages: z.boolean(),
      rank: z.boolean(),
      eventTimes: z.boolean(),
      mapLink: z.boolean(),
      distanceInHeader: z.boolean(),
      participateAfterEvent: z.boolean(),
      headerContent: z.array(z.string()),
    }),
    state: z.string(),
    predefinedTrack: z.boolean(),
    distance: z.number(),
    duration: z.number(),
    liveRanking: z.boolean(),
    target: z.string(),
  }),
  dataFeed: z.object({
    state: z.string(),
    geofenceRange: z.number(),
  }),
  netReplay: z.object({
    enabled: z.boolean(),
    splitId: z.nullable(z.string()),
    sliderDuration: z.number(),
  }),
  registration: z.object({
    externalRegistrationUrl: z.string(),
    rrRegistrationFormName: z.string(),
    rrRegistrationKey: z.string(),
    type: z.string(),
  }),
  brandedAppIds: z.array(z.string()),
});

export const EventSchema = z.object({
  modules: ModulesSchema,
  eventType: z.nativeEnum(SportTypes).nullable(),
  alertOptions: z.object({
    noMovementDuration: z.number(),
  }),
  slug: z.string().optional(),
  playerOptions: z.object({
    infoTabs: z.object({
      openByDefault: z.boolean(),
      defaultTab: z.string(),
      disabledTabs: z.array(z.string()),
    }),
    elevationChartOptions: z.object({
      min: z.nullable(z.number()),
      max: z.nullable(z.number()),
    }),
    mapType: z.string(),
    unitType: z.string(),
    showAllFlags: z.boolean(),
    showDistanceMarkers: z.boolean(),
    flagContent: z.string(),
    pinToShadowTrack: z.string(),
    markerTimeout: z.number(),
    breakTimeout: z.number(),
    replaySpeedup: z.number(),
    pollingInterval: z.number(),
    stepWidth: z.number(),
    liveDelay: z.number(),
    tailTimeout: z.number(),
    version: z.string(),
    viewport: z.nullable(z.any()),
    listOpenByDefault: z.boolean(),
    mappingDistance: z.number(),
    timeMode: z.string(),
    filterTagKeys: z.nullable(z.any()),
    defaultTagFilters: z.object({
      status: z.array(
        z.object({
          value: z.string(),
        }),
      ),
    }),
    hiddenStarterGroups: z.array(z.string()).optional(),
    visibleStarterGroups: z.array(z.string()),
  }),
  name: z.string(),
  startTime: z.string(),
  endTime: z.string(),
  isRepeating: z.boolean(),
  location: z.string(),
  eventWebsite: z.string(),
  eventContactMail: z.string(),
  eventDescription: z.string(),
  images: ImageSchema.optional(),
  visibility: z.string(),
  authorization: z.string(),
  id: z.string(),
  geo: z.object({
    geojsonHash: z.string().optional(),
    geosUpdatedAt: DateSchema,
    shadowgeojsonHash: z.string().optional(),
    shadowtrackId: ObjectIdSchema.nullable(),
  }),
});

export type BaseEvent = z.infer<typeof EventSchema>;

export const EventInfoSchema = z.array(
  EventSchema.extend({
    eventType: EventTypeSchema,
    geoJSON: z.object({
      type: z.string(),
      features: z.array(z.any()),
    }),
    starters: z.array(StarterSchema),
    splits: z.array(z.any()),
    shadowTrackIsVisible: z.boolean(),
  }),
);

export type EventInfo = z.infer<typeof EventInfoSchema>;
