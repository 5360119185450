import styled from '@emotion/styled';
import type { UnitType } from '@racemap/utilities/consts/events';
import type { TooltipComponentFormatterCallbackParams } from 'echarts';
import type { FC } from 'react';
import ReactDOMServer from 'react-dom/server';
import {
  labelFormatterDistance,
  labelFormatterElevation,
} from '../../Prediction/AnalyseComponents/utils';
import type { Marker } from './ElevationChart';

interface Props {
  elv: string;
  offset: string;
  marker?: Marker;
}

export const Tooltip: FC<Props> = ({ elv, offset, marker }) => {
  return (
    <Values>
      {marker != null && (
        <div>
          <b>{marker.name}</b>
        </div>
      )}
      <div>
        <b>Elevation:</b> {elv}
      </div>
      <div>
        <b>Offset:</b> {offset}
      </div>
    </Values>
  );
};

export const tooltipFormatter = (
  components: TooltipComponentFormatterCallbackParams,
  markers: Array<Marker>,
  unit: UnitType,
): string => {
  if (!Array.isArray(components)) return '';
  const serie = components.find(
    (c) => c.componentType === 'series' && c.componentSubType === 'line',
  );
  if (serie == null) return '';

  // @ts-expect-error missing types in lib
  const elv: string = labelFormatterElevation(serie.value[serie.encode.y[0]], unit, 2);
  // @ts-expect-error missing types in lib
  const offset: string = labelFormatterDistance(serie.value[serie.encode.x[0]], unit, 2);
  const marker = markers.find((s) => serie.dataIndex === s.coordinateIndex);

  return ReactDOMServer.renderToStaticMarkup(<Tooltip elv={elv} offset={offset} marker={marker} />);
};

const Values = styled.div`
  display: flex;
  flex-direction: column;
`;
