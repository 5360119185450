import { VisibilityStates } from '@racemap/utilities/consts/events';
import { isArchivedEvent } from '@racemap/utilities/functions/event';
import { Button } from 'antd';
import { useStore } from 'src/store/reducers';
import { useCurrentEvent } from '../../lib/customHooks';
import { IconArchive, IconDearchive } from '../Icon';

export function ArchiveButton() {
  const currentEvent = useCurrentEvent();
  const { updateEvent } = useStore((s) => s.events.actions);
  if (currentEvent == null) return null;
  const isArchived = isArchivedEvent(currentEvent);

  const handleClick = () => {
    if (currentEvent == null) return;
    if (isArchived) {
      updateEvent(currentEvent.id, [{ key: 'visibility', newValue: VisibilityStates.UNLISTED }]);
    } else {
      updateEvent(currentEvent.id, [{ key: 'visibility', newValue: VisibilityStates.ARCHIVED }]);
    }
  };

  return (
    <Button
      icon={isArchived ? <IconDearchive /> : <IconArchive />}
      danger={!isArchived}
      type={isArchived ? 'primary' : 'default'}
      onClick={handleClick}
    >
      {isArchived ? 'Dearchive' : 'Archiv'}
    </Button>
  );
}
