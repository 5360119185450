import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { VisibilityLabels } from '@racemap/utilities/consts/events';
import { compareNumber } from '@racemap/utilities/functions/compareNumber';
import {
  isArchivedEvent,
  isHiddenChildEvent,
  isNotActivatedEvent,
} from '@racemap/utilities/functions/event';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import { Alert, Button, Flex, Popconfirm, Space } from 'antd';
import type { Immutable } from 'immer';
import { DateTime } from 'luxon';
import type React from 'react';
import { useCurrentEvent } from '../lib/customHooks';
import { EventLogo } from './BasicComponents/EventLogo';
import { EventLink } from './BasicComponents/Links';
import { LocalizedTimestampsPopover } from './BasicComponents/LocalizedTimestamps';
import { SortableTable } from './BasicComponents/SortableTable';
import type { ColumnsType } from './BasicComponents/SortableTable/SortableTable';
import { EventInfoBadges } from './EventInfoBadges';
import { IconInvisible, IconTrash, IconVisible } from './Icon';

type Props = {
  events: Immutable<Array<RacemapEvent>>;
  eventClassName?: string;
  onEventRemove?: (event: Immutable<RacemapEvent>) => void;
  onSwapEvents?: (e1: Immutable<RacemapEvent>, e2: Immutable<RacemapEvent>) => void;
  onVisibilityToggle: (event: Immutable<RacemapEvent>) => void;
};

export const EventList: React.FC<Props> = ({
  eventClassName,
  onEventRemove,
  onSwapEvents,
  onVisibilityToggle,
  events,
}) => {
  const currentEvent = useCurrentEvent();
  const sortedEvents = [...events].sort((eA, eB) => compareNumber(eA.index, eB.index));

  if (currentEvent == null) return null;
  const noEventsActivatedOrVisible = events.every((e) => isHiddenChildEvent(e));

  const columns: ColumnsType<Immutable<RacemapEvent>> = [
    {
      dataIndex: 'id',
      key: 'logo',
      render: (id: string) => renderLogoCell(id),
    },
    {
      title: eventClassName || 'Event',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, event) => renderEventNameCell(name, event.id),
    },
    {
      title: 'Starttime',
      dataIndex: 'startTime',
      key: 'startTime',
      render: (startTime: string) => renderDatetimeCell(startTime),
    },
    {
      title: 'Endtime',
      dataIndex: 'endTime',
      key: 'endTime',
      render: (endTime: string) => renderDatetimeCell(endTime),
    },
    {
      title: 'State',
      dataIndex: 'id',
      key: 'event_state',
      render: (_id, event) => renderBadgeCellVisibility(event),
      width: 200,
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'maintenance',
      className: 'maintenance-cell',
      render: (_id, event) => renderMaintenceCell({ event, onEventRemove, onVisibilityToggle }),
      width: 140,
    },
  ];

  return (
    <Flex gap={7} vertical>
      <Table
        dataSource={sortedEvents}
        pagination={false}
        size="middle"
        rowClassName={(record) =>
          isNotActivatedEvent(record) || isArchivedEvent(record) ? 'disabled-row' : ''
        }
        onRow={getRowProps}
        columns={columns}
        onOrderChange={(_data, entry1, entry2) => onSwapEvents?.(entry1, entry2)}
      />
      {noEventsActivatedOrVisible && (
        <Alert
          message="No events are activated or visible! You will not see any events in the player of the group."
          type="error"
          showIcon
        />
      )}
    </Flex>
  );
};

const Table = styled(SortableTable<Immutable<RacemapEvent>>)` 
  border: 1px solid ${RacemapColors.LightGray};
  border-bottom: unset;

  .ant-table-tbody > tr.disabled-row {
    td > * {
    opacity: 0.5;
    }
    maintenance-cell > * {
      opacity: 1;
    }
  }

  .ant-table-tbody > tr.is-dragging > td {
    border-top: 1px solid ${RacemapColors.LightGray};
  }
`;

const getRowProps = (record: Immutable<RacemapEvent>): { title?: string } => {
  if (record == null) return {};
  if (isArchivedEvent(record))
    return {
      title: `The Event is ${
        VisibilityLabels[record.visibility]
      } State. So it is not visibel for the spectators in the group.`,
    };
  if (isNotActivatedEvent(record))
    return {
      title: 'The Event is still trial. So it is not activated yet.',
    };

  return {};
};

const renderMaintenceCell = ({
  event,
  onEventRemove,
  onVisibilityToggle,
}: {
  event: Immutable<RacemapEvent>;
  onEventRemove: Props['onEventRemove'];
  onVisibilityToggle: Props['onVisibilityToggle'];
}) => {
  const isUnlistedEvent = isHiddenChildEvent(event);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <FullWidthButton
        icon={isUnlistedEvent ? <IconVisible /> : <IconInvisible />}
        disabled={isNotActivatedEvent(event) || isArchivedEvent(event)}
        onClick={() => onVisibilityToggle(event)}
      >
        {isUnlistedEvent ? 'Show' : 'Hide'}
      </FullWidthButton>
      {onEventRemove != null && (
        <Popconfirm onConfirm={() => onEventRemove(event)} title="Are you sure?">
          <FullWidthButton icon={<IconTrash />} danger>
            Remove
          </FullWidthButton>
        </Popconfirm>
      )}
    </Space>
  );
};

const renderLogoCell = (id: string) => {
  return <EventLogo eventId={id} size={45} />;
};

const renderEventNameCell = (name: string, id?: string) => {
  if (id == null) return <span>{name}</span>;
  return <EventLink eventId={id} eventName={name} />;
};

const renderDatetimeCell = (datetime: string) => (
  <LocalizedTimestampsPopover
    showUTC
    timestamps={[{ timestamp: DateTime.fromISO(datetime) }]}
    label={DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED)}
  />
);

const renderBadgeCellVisibility = (event?: Immutable<RacemapEvent>) => (
  <EventInfoBadges event={event} />
);

const FullWidthButton = styled(Button)`
  width: 100%;
`;
