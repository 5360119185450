import { DateSchema, type ObjectId, ObjectIdSchema } from '@racemap/sdk/schema/base';
import type { StripeProducts } from '@racemap/sdk/schema/billing/StripeProducts';
import {
  BillableItemTypes,
  UserDocumentSchema,
  type User as ZodUser,
} from '@racemap/sdk/schema/user';
import type { Document, Types } from 'mongoose';
import type Stripe from 'stripe';
import { z } from 'zod';
import type { Brand } from './brand';

export {
  UserDocumentSchema,
  type UserDocument,
  type Admin,
  type UserDocumentWithStripeCustomerInfos,
} from '@racemap/sdk/schema/user';

export type { DailyLoad, DailyLoads } from '@racemap/sdk/schema/billing';

export enum TOSVersions {
  NONE = 'NONE',
  '2019-10' = '2019-10',
}

export const CURRENT_TOS_VERSION = TOSVersions['2019-10'];

export type TaxId = {
  value: string;
};

export type Integrations = {
  stripeId?: string;
  mailchimpSubscribed: boolean;
  raceResultApiKey: string | null;
  raceResultCustomerId: string | null;
  insidersAccessToken: string | null;
  insidersRefreshToken: string | null;
  insidersExpiresAt: string | null;
};

export type UserStatistics = {
  numberCreatedEvents: number;
  numberPaidEvents: number;
};

export type UserCredentials = {
  email: string;
  password: string;
};

export interface UserSubscriptions {
  [BillableItemTypes.APP]: number;
}

export type User_Legacy = {
  id: string;
  admin?: boolean;
  isBetaUser?: boolean;
  receivedAt?: number;
  updatedAt?: string;
  createdAt?: string;
  isConfirmed?: boolean;
  payments?: {
    lastUsageSync: string;
  };
  name?: string;
  company?: string;
  email?: string;
  phoneNumber?: string;
  country?: string;
  useTimingAPI?: boolean;
  isReseller?: boolean;
  password?: string;
  fakeUser?: boolean;
  acceptedTOSVersion?: TOSVersions;
  apiToken?: string;
  stats?: UserStatistics;
  parentId: string | ObjectId | null;
  integrations?: Integrations;
  subscriptions: UserSubscriptions;
  skipNotifications?: boolean;
  checkout?: {
    address: Stripe.Address;
    taxIds: Array<Stripe.TaxId>;
    company?: string;
    phone?: string;
    shipping: Stripe.Customer.Shipping | null;
    defaultCard: Stripe.Source.Card | null;
  };
  createdByTest?: boolean;
};
export type ResellerLegacy = User_Legacy & {
  isReseller: true;
};
export type BetaUserLegacy = User_Legacy & {
  isBetaUser: true;
};
export type ChildUserLegacy = User_Legacy & {
  parentId: string;
};
export type RegularUserLegacy = User_Legacy & {
  admin: false;
  parentId: null;
  isReseller: false;
};
export type AdminLegacy = User_Legacy & {
  admin: true;
};

export type User = ZodUser;
export type Users = Array<User_Legacy>;

export const UserSnapshotDocumentSchema = z.object({
  _id: ObjectIdSchema,
  userId: ObjectIdSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
  user: UserDocumentSchema,
});

export type UserSnapshotDocument = z.infer<typeof UserSnapshotDocumentSchema> &
  Document<Types.ObjectId>;

export interface UserWithBrands extends User_Legacy {
  brands: Array<Brand>;
}

export interface BorrowerDocument extends Document {
  id: ObjectId;
  startTime: Date;
  endTime: Date;
}

// editor user added from backend to events
export type Editor = User_Legacy & {
  id: string | ObjectId;
  name: string;
  isReseller: boolean;
};

export type Editors = Array<Editor>;

// user response from search user input
export type SearchedUser = Editor & {
  email: string;
};

export type SearchedUsers = Array<SearchedUser>;

export type BillableNowItemTypes = Exclude<
  BillableItemTypes,
  | BillableItemTypes.SIM_CARD
  | BillableItemTypes.TRACKER_MANAGEMENT
  | BillableItemTypes.APP
  | BillableItemTypes.SMS
  | BillableItemTypes.ACTIVATE_EVENT
  | BillableItemTypes.EVENT_DAY
  | BillableItemTypes.DATA_USAGE_ZONE_1
  | BillableItemTypes.DATA_USAGE_ZONE_2
  | BillableItemTypes.DATA_USAGE_ZONE_3
  | BillableItemTypes.EXTEND_EVENT
>;
export type FreeBillableItemTypes =
  | BillableItemTypes.FREE_EVENT_CYCLE
  | BillableItemTypes.FREE_TIMING
  | BillableItemTypes.FREE_MONITOR
  | BillableItemTypes.FREE_SPONSOR
  | BillableItemTypes.FREE_DATA_FEED;
export type BillableNowCountList = Partial<Record<StripeProducts, number>>;
export type FreeItemCounts = Record<FreeBillableItemTypes, number>;

export const BillingInvoiceSchema = z.object({
  id: z.string(),
  invoicePdf: z.string(),
  invoiceWeb: z.string(),
  createdAt: z.string(),
  paid: z.boolean(),
  number: z.string(),
  amount: z.number(),
});

export type UsageGroupedByTimeKeyAndZone = {
  [timeKey: string]: {
    [BillableItemTypes.DATA_USAGE_ZONE_1]?: number;
    [BillableItemTypes.DATA_USAGE_ZONE_2]?: number;
    [BillableItemTypes.DATA_USAGE_ZONE_3]?: number;
  };
};

export type BillingInvoice = {
  id: string;
  invoicePdf: string;
  invoiceWeb: string;
  createdAt: string;
  paid: boolean;
  number: string;
  amount: number;
};

export const SessionSchema = z.object({
  expires: z.string(),
  lastModified: z.string(),
  id: ObjectIdSchema,
  createdAt: z.string(),
  userAgent: z.string(),
  ip: z.string(),
  currentSession: z.boolean().optional(),
});

export type Session = z.infer<typeof SessionSchema>;

export const SessionsSchema = z.array(SessionSchema);

export type Sessions = z.infer<typeof SessionsSchema>;

export const ListSessionsResponseSchema = z.object({
  sessions: SessionsSchema,
  currentSessionId: ObjectIdSchema.optional(),
});

export type ListSessionsResponse = z.infer<typeof ListSessionsResponseSchema>;
