import type { HydratedDocument } from 'mongoose';
import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from './base';
import type { UserDocument } from './user';

export enum SimCardStates {
  AVAILABLE = 'AVAILABLE',
  PENDING_PROVISIONING = 'PENDING_PROVISIONING',
  AKTIV = 'AKTIV',
  SUSPENDED = 'SUSPENDED',
  PENDING_DEPROVISIONING = 'PENDING_DEPROVISIONING',
  UNPROVISIONED = 'UNPROVISIONED',
}

export const SimCardSchema = z.object({
  id: ObjectIdSchema,
  iccid: z.string().max(23).min(18),
  imsi: z.string().max(15).min(6).nullish(),
  msisdn: z.string().max(15).nullable(),
  friendlyName: z.string().nullable(),
  lastIpAddress: z.string().nullable(),
  status: z.nativeEnum(SimCardStates),
  userId: ObjectIdSchema.nullable(),
  updatedAt: DateSchema,
  createdAt: DateSchema,
  group: z.string().nullable(),
  lastActivationAt: DateSchema.nullable(),
  lastTerminationAt: DateSchema.nullable(),
  lastConnectionAt: DateSchema.nullable(),
  lastDisconnectionAt: DateSchema.nullable(),
  currentDataUsage: z.number(),
  device: z
    .object({
      name: z.string(),
      id: ObjectIdSchema,
    })
    .nullish(),
});

export type SimCard = z.infer<typeof SimCardSchema>;

export interface SimCardDocumentMethods {
  canEdit(user: UserDocument | Express.User | null | undefined): Promise<boolean>;
  canRead(user: UserDocument | Express.User | null | undefined): Promise<boolean>;
}

export type SimCardDocument = HydratedDocument<SimCard, SimCardDocumentMethods>;
