import { makeApi, makeEndpoint, prefixApi } from '@zodios/core';
import { z } from 'zod';
import { ObjectIdHexStringSchema } from '../schema/base';
import { MessageTemplatePrototypeSchema, MessageTemplateSchema } from '../schema/messageTemplates';

const prefix = '/api/v1/message-templates';

const listMessageTemplates = makeEndpoint({
  method: 'get',
  path: '/',
  alias: 'listMessageTemplates',
  description: 'List message templates',
  response: z.array(MessageTemplateSchema),
  parameters: [
    {
      name: 'userId',
      type: 'Query',
      description: 'User ID',
      schema: ObjectIdHexStringSchema.optional(),
      optional: true,
    },
  ],
});

const createMessageTemplate = makeEndpoint({
  method: 'post',
  path: '/',
  alias: 'createMessageTemplate',
  description: 'Create message template',
  parameters: [
    {
      name: 'messageTemplate',
      type: 'Body',
      description: 'Message Template Infos',
      schema: MessageTemplatePrototypeSchema,
    },
  ],
  response: MessageTemplateSchema,
});

const getMessageTemplate = makeEndpoint({
  method: 'get',
  path: '/:messageTemplateId',
  alias: 'getMessageTemplate',
  description: 'Get message template',
  parameters: [
    {
      name: 'messageTemplateId',
      type: 'Path',
      description: 'Message Template ID',
      schema: ObjectIdHexStringSchema,
    },
  ],
  response: MessageTemplateSchema,
});

const updateMessageTemplate = makeEndpoint({
  method: 'patch',
  path: '/:messageTemplateId',
  alias: 'updateMessageTemplate',
  description: 'Update message template',
  parameters: [
    {
      name: 'messageTemplateId',
      type: 'Path',
      description: 'Message Template ID',
      schema: ObjectIdHexStringSchema,
    },
    {
      name: 'messageTemplatePatch',
      type: 'Body',
      description: 'Patch of the Message Template Infos',
      schema: MessageTemplatePrototypeSchema.partial(),
    },
  ],
  response: MessageTemplateSchema,
});

const deleteMessageTemplate = makeEndpoint({
  method: 'delete',
  path: '/:messageTemplateId',
  alias: 'deleteMessageTemplate',
  description: 'Delete message template',
  parameters: [
    {
      name: 'messageTemplateId',
      type: 'Path',
      description: 'Message Template ID',
      schema: ObjectIdHexStringSchema,
    },
  ],
  response: z.object({ message: z.string() }),
});

const endpoints = makeApi([
  listMessageTemplates,
  createMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate,
  getMessageTemplate,
]);
export const messageTemplatesApi = prefixApi(prefix, endpoints);
