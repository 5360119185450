import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { FC } from 'react';
import { IconTurnRight } from '../../Icon';
import { Devider } from '../Devider/Devider';
import { LoadingIndicator } from '../LoadingIndicator';
import { HStack, VStack } from '../MetaComponent';

interface Props {
  results: Results;
  isLoading?: boolean;
  title?: string;
}

export type Results = Array<{
  type: 'page';
  title: string;
  description: string;
  link: string;
}>;

export const ResultsList: FC<Props> = ({
  results,
  isLoading = false,
  title = 'Search Results',
}) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Devider bottomSpace="3px" />
      {isLoading && <LoadingIndicator borderless />}
      {!isLoading && results.length === 0 && <NoResult>No results found.</NoResult>}
      {!isLoading && results.map((r) => <Result key={r.title} {...r} />)}
    </Container>
  );
};

const Result = ({ title, description, link }: Results[0]) => {
  return (
    <ResultContainer>
      <a href={link} target="_blank" rel="noreferrer">
        <HStack>
          <TitleAndSubtitle>
            <h3>{title}</h3>
            <p title={description}>{description}</p>
          </TitleAndSubtitle>

          <HStack gap="5px" align="center" className="open-info">
            Open
            <IconTurnRight />
          </HStack>
        </HStack>
      </a>
    </ResultContainer>
  );
};

const TitleAndSubtitle = styled(VStack)`
  width: 100%;
  overflow: hidden;

  h3 {
    margin-bottom: 0;
    font-size: 1.2rem;
  }
  p {
    color: ${RacemapColors.DarkGray};
    margin-bottom: 0;
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ResultContainer = styled.div`
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 100%;

  .open-info {
      display: none;
      font-weight: 300;
  }

  &:hover {
    background: ${RacemapColors.LightGray} !important;

    .open-info {
      display: flex;
    }
  }
`;

const Title = styled.h2`
  margin: 0px 5px;
  color: ${RacemapColors.DarkGray};
  font-weight: 500;
  font-size: 1rem;
`;

const NoResult = styled.div`
  padding: 5px 10px;
  width: 100%;
  color: ${RacemapColors.DarkDarkGray};
  font-weight: 400;
  font-size: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 7px 0;
`;
