import { EventTypes } from '@racemap/sdk/schema/events';
import { DefaultLogoUrl } from '@racemap/utilities/consts/events';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import type { Immutable } from 'immer';
import { Badge } from 'react-bootstrap';
import type { CurrentEvent } from '../store/events/events_reducers';

export function getPlayerUrl(event: Immutable<RacemapEvent | CurrentEvent>) {
  return `${window.location.origin}/player/${
    event.slug != null && event.slug !== '' ? event.slug : event.id
  }`;
}

export function getTimingUrl(event: Immutable<RacemapEvent | CurrentEvent>) {
  return `${window.location.origin}/timing/${
    event.slug != null && event.slug !== '' ? event.slug : event.id
  }`;
}

export function getLeaderboardUrl(event: Immutable<RacemapEvent | CurrentEvent>) {
  return getTimingUrl(event);
}

export function getLogoUrl(event: Immutable<RacemapEvent | CurrentEvent>) {
  return event.images != null ? event.images.app : DefaultLogoUrl;
}

export function getDataFeedUrl(event: Immutable<RacemapEvent | CurrentEvent>) {
  return `${window.location.origin}/api/data/v1/${event.id}`;
}

export function getMonitorUrl(event: Immutable<RacemapEvent | CurrentEvent>) {
  return `${window.location.origin}/monitor/${event.id}`;
}

export function getBetaMonitorUrl(event: Immutable<RacemapEvent | CurrentEvent>) {
  return `${window.location.origin}/admin/monitor/beta/${event.id}`;
}

export function getParentEditorUrl(event: Immutable<RacemapEvent | CurrentEvent>) {
  return `${window.location.origin}/admin/events/${event.parent}`;
}

export function getTypeBadge(eventType: EventTypes) {
  switch (eventType) {
    case EventTypes.CONTEST:
      return (
        <Badge variant="warning" title="contest">
          C
        </Badge>
      );
    case EventTypes.CONTEST_GROUP:
      return (
        <Badge variant="info" title="contest group">
          CG
        </Badge>
      );
    case EventTypes.REGULAR:
      return (
        <Badge variant="success" title="regular">
          R
        </Badge>
      );
    case EventTypes.STAGE:
      return (
        <Badge variant="primary" title="stage">
          S
        </Badge>
      );
    case EventTypes.STAGE_GROUP:
      return (
        <Badge variant="danger" title="stage group">
          SG
        </Badge>
      );
  }
}
