import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { FC } from 'react';
import { IconSearch } from '../../Icon';
import { Hotkeys } from '../Hotkeys/Hotkeys';
import { HStack } from '../MetaComponent';

interface Props {
  onClick: () => void;
  text: string;
  hotkeyActive?: boolean;
}

export const PseudoInput: FC<Props> = ({ onClick, text, hotkeyActive }) => {
  return (
    <Container onClick={onClick}>
      <IconAndText>
        <IconSearch />
        {text}
      </IconAndText>

      <Hotkeys
        keyValue="M"
        needCtrl
        needShift
        withPlus
        onTrigger={() => hotkeyActive && onClick()}
      />
    </Container>
  );
};

const Container = styled.div`
    background-color: ${RacemapColors.LightGray};
    width: 100%;
    padding: 3px 8px;
    height: 33.5px;
    border-radius: 5px;
    border: 1px solid ${RacemapColors.Gray};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    &:hover {
        background-color: ${RacemapColors.Gray};
    }
`;

const IconAndText = styled(HStack)`
    gap: 5px;
    color: ${RacemapColors.DarkGray};
`;
