import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from './base';

export const ApiTokenSchema = z.object({
  id: ObjectIdSchema,
  userId: ObjectIdSchema,
  name: z.string(),
  updatedAt: DateSchema,
  createdAt: DateSchema,
  decodedToken: z.string().optional(),
});

export type ApiToken = z.infer<typeof ApiTokenSchema>;

export const ApiTokensSchema = z.array(ApiTokenSchema);

export type ApiTokens = z.infer<typeof ApiTokensSchema>;
