import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import type { CSSProperties } from 'react';
export type Message = {
  message: string;
  variant: 'error' | 'info';
};

export const EventMessageVisibility = ({ message, variant = 'error' }: Message) => {
  return (
    <>
      <TextMessage color={variant === 'error' ? RacemapColors.DarkRed : RacemapColors.DarkGray}>
        {message}
      </TextMessage>
    </>
  );
};

const TextMessage = styled.div<{ color: CSSProperties['color'] }>`
  width: 100%;
  color: ${({ color }) => color};
`;
