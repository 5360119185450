import styled from '@emotion/styled';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { DatePicker } from 'antd';
import type { Position } from 'geojson';
import type { DateTime } from 'luxon';
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon';
import type { PickerFocusEventHandler } from 'rc-picker/lib/interface';
import { type FC, useEffect, useId, useRef } from 'react';
import { useActiveElement, useElementDimensions } from '../../../lib/customHooks';
import { LocalizedTimestampsPopover } from '../LocalizedTimestamps';
import { ExtraPanelHeader } from './ExtraPanelHeader';

const { RangePicker } = DatePicker.generatePicker<DateTime>(luxonGenerateConfig);
type RangePickerProps = React.ComponentProps<typeof RangePicker>;

export type Props = RangePickerProps & {
  showUTCTimestamp?: boolean;
  geoPosition?: Position | undefined;
};

export const DateRangePicker: FC<Props> = ({
  id,
  showUTCTimestamp = false,
  geoPosition,
  onBlur,
  ...props
}) => {
  const pickerId = useId();
  const currentDateInputInFocus = useFocusedInput();
  const containerRef = useRef(null);
  const { width: containerWidth } = useElementDimensions(containerRef);
  const [startTime, endTime] = props.value || [];

  useEffect(() => {
    // the inputs of the date range picker are not clearly identifiable
    // we add here now a unique id to each input
    const inputs = document.querySelectorAll(
      `#${containerPrefix}${CSS.escape(pickerId)} .ant-picker-input > input`,
    );
    for (const [index, input] of inputs.entries()) {
      input.id = getInputId(pickerId, index === 0 ? 'start' : 'end');
    }
  }, []);

  const handleInputBlur: PickerFocusEventHandler = (e, info) => {
    // stop the propergation to prevent that the save event is triggered
    e.stopPropagation();

    // workaround. prevent blur if the focus switch von one input to another
    // trigger on blur only if the active element is not a date-range-picker-input
    setTimeout(() => {
      const aElId = document.activeElement?.id;
      if (!aElId?.endsWith(`${inputIdMiddle}${pickerId}`)) {
        const container = document.getElementById(`${containerPrefix}${pickerId}`);
        container?.dispatchEvent(e.nativeEvent);
        onBlur?.(e, info);
      }
    }, 100);
  };

  return (
    <Container id={getContainerId(pickerId)} ref={containerRef}>
      <RangePicker
        id={id}
        {...props}
        changeOnBlur
        onBlur={handleInputBlur}
        format={props.format || getPickerFormat(props.value, containerWidth)}
        panelRender={(panelNode) => (
          <>
            <ExtraPanelHeader currentRange={currentDateInputInFocus} />
            {panelNode}
          </>
        )}
      />
      {(showUTCTimestamp || geoPosition != null) && (
        <LocalizedTimestampsPopover
          showUTC={showUTCTimestamp}
          timestamps={[
            { timestamp: startTime, label: 'Start Time' },
            { timestamp: endTime, label: 'End Time' },
          ]}
          position={
            geoPosition && {
              lng: geoPosition[0],
              lat: geoPosition[1],
            }
          }
        />
      )}
    </Container>
  );
};

const defaultFormat = 'yyyy-MM-dd HH:mm:ss' as const;
const onlyStartTimeFormat = 'HH:mm:ss' as const;
type FormatFunction = (value: DateTime) => string;

const getPickerFormat = (
  range: RangePickerProps['value'],
  inputWidth: number,
): string | FormatFunction => {
  if (range == null) return defaultFormat;
  const startTime = range[0];
  const endTime = range[1];
  if (startTime == null || endTime == null) return defaultFormat;
  const isSameDay = startTime?.hasSame(endTime, 'day');

  if (isSameDay && inputWidth < 400)
    return (value: DateTime) => {
      if (+value === +endTime) return value.toFormat(onlyStartTimeFormat);
      return value.toFormat(defaultFormat);
    };

  return defaultFormat;
};

const Container = styled.div`
  .ant-picker-range {
    padding: 0 11px 0%;
  }

  .ant-picker-input {
    padding: 4px 0;

    :first-of-type {
      min-width: 170px;
    }
  }

  .ant-picker-focused .ant-picker-input-active {
    background-color: ${RacemapColors.LightGray};
  }
`;

const inputIdMiddle = '-date-range-picker-input-';
const containerPrefix = 'date-range-picker-';

const getInputId = (pickerId: string, range: 'start' | 'end') =>
  `${range}${inputIdMiddle}${pickerId}`;
const getContainerId = (pickerId: string) => `${containerPrefix}${pickerId}`;

const useFocusedInput = (): 'start' | 'end' | null => {
  const { activeElement } = useActiveElement();

  if (!activeElement?.id?.includes(inputIdMiddle)) return null;

  return activeElement.id.includes('start') ? 'start' : 'end';
};
