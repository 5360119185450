import type { Immutable } from 'immer';
import { AuthorizationStates, ModulesStates, VisibilityStates } from '../consts/events';
import type { RacemapEvent } from '../types/events';
import type { EventTrackObject, SplitObject } from '../types/geos';
import { isRacemapTrack, isSplitPoint } from './validation';

// event checks

export function isHiddenChildEvent(
  event: Pick<Immutable<RacemapEvent>, 'visibility' | 'authorization'>,
): boolean {
  return (
    event.visibility === VisibilityStates.DRAFT ||
    event.visibility === VisibilityStates.UNLISTED ||
    isNotActivatedEvent(event) ||
    isArchivedEvent(event)
  );
}

export function isNotActivatedEvent(
  event: Pick<Immutable<RacemapEvent>, 'authorization'>,
): boolean {
  return event.authorization === AuthorizationStates.NONE;
}

export function isArchivedEvent(event: Pick<Immutable<RacemapEvent>, 'visibility'>): boolean {
  return event.visibility === VisibilityStates.ARCHIVED;
}

export function calculateActivatedPeriods(
  dateEnd: Date | string | null,
  dateStart: Date | string | null,
): number {
  const date1 = dateEnd != null ? new Date(dateEnd) : new Date();
  const date2 = dateStart != null ? new Date(dateStart) : new Date();
  const years = new Date(date1).getFullYear() - new Date(date2).getFullYear();
  const month = new Date(date1).getMonth() - new Date(date2).getMonth();
  const days = new Date(date1).getDate() - new Date(date2).getDate();
  const activatedPeriods = Math.ceil((years * 12 + month + (days > 0 ? 1 : 0)) / 3);
  return activatedPeriods;
}

export function currentEventTimes(
  event?: Immutable<RacemapEvent> | null,
): [number | undefined, number | undefined] {
  if (!event?.startTime || !event?.endTime) return [undefined, undefined];
  const startTime = Date.parse(event.startTime);
  const endTime = Date.parse(event.endTime);
  const eventDuration = endTime - startTime;
  const repeatTimeOffset = event.isRepeating
    ? Math.floor((Date.now() - startTime) / eventDuration) * eventDuration
    : 0;
  return [startTime + repeatTimeOffset, endTime + repeatTimeOffset];
}

export function getTimekeepingSplits(
  event?: Immutable<RacemapEvent> | null,
): Immutable<Array<SplitObject>> {
  return getSplitsOfTrack(event).filter((split) => split.properties.timekeeping);
}

export function getSplitsOfTrack(
  event?: Immutable<{ geo: RacemapEvent['geo'] }> | null,
): Array<SplitObject> {
  if (
    event == null ||
    event.geo == null ||
    event.geo.shadowtrackId == null ||
    event.geo.features == null
  )
    return [];
  const trackId = event.geo.shadowtrackId;
  return event.geo.features
    .filter(isSplitPoint)
    .filter((f) => f.properties.lineStringId === trackId)
    .sort((a, b) =>
      a.properties.offset == null
        ? 1
        : b.properties.offset == null
          ? 1
          : a.properties.offset - b.properties.offset,
    );
}

export function getSubReaderIds(splits: Immutable<Array<SplitObject>>): Set<string> {
  return new Set(
    splits.reduce<Array<string>>((prev, s) => {
      if (s.properties.readerIds == null) return prev;
      return prev.concat(s.properties.readerIds);
    }, []),
  );
}

export function getTracksOfEvent(event: Immutable<RacemapEvent>): Array<EventTrackObject> {
  const features = event.geo?.features;
  if (features == null) return [];

  return features.filter(isRacemapTrack);
}

export function generateStarterDeviceIdsString(
  event?: Immutable<RacemapEvent> | null,
): Array<string> {
  if (!event) return [];

  return Array.from(event.starters?.entries() || [])
    .map(([_, starter]) => starter.deviceId)
    .filter((id): id is string => id !== null);
}

// modules helpers

export function isActivated(modules?: { state?: ModulesStates; enabled?: boolean }): boolean {
  if (modules == null) return false;
  if (modules.state != null) return modules.state === ModulesStates.ACTIVATED;
  return !!modules.enabled;
}

export function isEnabled(modules: { state?: ModulesStates }): boolean {
  if (modules.state !== null) return modules.state === ModulesStates.ENABLED;
  return false;
}
