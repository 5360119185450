import type { ObjectId } from '@racemap/sdk/schema/base';
import type { Document } from 'mongoose';
import type { UserDocument } from './users';

export enum EntityEventType {
  SOS = 'SOS',
  NO_MOVEMENT = 'NO_MOVEMENT',
  AWAY_FROM_TRACK = 'AWAY_FROM_TRACK',
  LOW_BATTERY = 'LOW_BATTERY',
  UNKNOWN = 'UNKNOWN',
  POWER_OFF = 'POWER_OFF',
  CRASH = 'CRASH',
  HEARTBEAT = 'HEARTBEAT',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  REBOOT = 'REBOOT',
  DEVICE_INFO = 'DEVICE_INFO',
  NONE = 'NONE',

  // Configuration events
  CONFIG_UPDATE_COMMAND_STARTING = 'CONFIG_UPDATE_COMMAND_STARTING',
  CONFIG_UPDATE_COMMAND_CONFIRMED = 'CONFIG_UPDATE_COMMAND_CONFIRMED',
  CONFIG_UPDATE_COMMAND_REFUSED = 'CONFIG_UPDATE_COMMAND_REFUSED',
  CONFIG_UPDATE_PROCESS_REFUSED_LOW_BATTERY = 'CONFIG_UPDATE_PROCESS_REFUSED_LOW_BATTERY',
  CONFIG_DOWNLOAD_STARTING = 'CONFIG_DOWNLOAD_STARTING',
  CONFIG_DOWNLOAD_SUCCESS = 'CONFIG_DOWNLOAD_SUCCESS',
  CONFIG_DOWNLOAD_FAILED = 'CONFIG_DOWNLOAD_FAILED',
  CONFIG_DEVICE_UPDATE_STARTING = 'CONFIG_DEVICE_UPDATE_STARTING',
  CONFIG_DEVICE_UPDATE_SUCCESS = 'CONFIG_DEVICE_UPDATE_SUCCESS',
  CONFIG_DEVICE_UPDATE_FAILED = 'CONFIG_DEVICE_UPDATE_FAILED',
}

export enum EntityType {
  STARTERS = 'STARTERS',
  DEVICES = 'DEVICES',
  EVENTS = 'EVENTS',
}

export const EntityEventLabels: Record<EntityEventType, string> = {
  [EntityEventType.SOS]: 'SOS',
  [EntityEventType.NO_MOVEMENT]: 'No movement',
  [EntityEventType.AWAY_FROM_TRACK]: 'Away from track',
  [EntityEventType.LOW_BATTERY]: 'Low battery',
  [EntityEventType.POWER_OFF]: 'Power off',
  [EntityEventType.UNKNOWN]: 'Unknown',
  [EntityEventType.CRASH]: 'Crash/Fall Accident',
  [EntityEventType.HEARTBEAT]: 'Heartbeat',
  [EntityEventType.OFFLINE]: 'Offline',
  [EntityEventType.ONLINE]: 'Online',
  [EntityEventType.REBOOT]: 'Reboot',
  [EntityEventType.DEVICE_INFO]: 'Device info',
  [EntityEventType.NONE]: 'None',
  [EntityEventType.CONFIG_UPDATE_COMMAND_STARTING]: 'Update command starting',
  [EntityEventType.CONFIG_UPDATE_COMMAND_CONFIRMED]: 'Update command confirmed',
  [EntityEventType.CONFIG_UPDATE_COMMAND_REFUSED]: 'Update command refused',
  [EntityEventType.CONFIG_UPDATE_PROCESS_REFUSED_LOW_BATTERY]:
    'Update process refused due to low battery',
  [EntityEventType.CONFIG_DOWNLOAD_STARTING]: 'Download starting',
  [EntityEventType.CONFIG_DOWNLOAD_SUCCESS]: 'Download success',
  [EntityEventType.CONFIG_DOWNLOAD_FAILED]: 'Download failed',
  [EntityEventType.CONFIG_DEVICE_UPDATE_STARTING]: 'Device update starting',
  [EntityEventType.CONFIG_DEVICE_UPDATE_SUCCESS]: 'Device update success',
  [EntityEventType.CONFIG_DEVICE_UPDATE_FAILED]: 'Device update failed',
};

export interface EntityEventRaw {
  entityType: EntityType;
  eventType: EntityEventType;
  location?: {
    lat?: number;
    lng?: number;
  };
  information?: string;
}

export type EntityEventDocument = EntityEventRaw &
  Document & {
    creatorId?: ObjectId;
    updaterId?: ObjectId;
    entityId: ObjectId;
    occuredAt: Date;
    createdAt?: Date;
    updatedAt?: Date;
    solvedAt?: Date | null;
    solvedBy?: ObjectId;

    canEdit: (this: EntityEventDocument, user?: UserDocument | null) => Promise<boolean>;
    canRead: (this: EntityEventDocument, user?: UserDocument | null) => Promise<boolean>;
    canSolve: (this: EntityEventDocument, user?: UserDocument | null) => Promise<boolean>;
    solve: (this: EntityEventDocument, user?: UserDocument) => Promise<void>;
  };
export interface EntityEvent extends EntityEventRaw {
  id: string;
  updaterId: string;
  occuredAt: string;
  createdAt: string;
  updatedAt: string;
  solvedAt: string | null;
  solvedBy: string | null;
  entityId: string;
  creatorId: string;
}

export type EntityEvents = Array<EntityEvent>;
