import styled from '@emotion/styled';
import { TZServiceAPICLient } from '@racemap/utilities/api-client';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { Popover } from 'antd';
import { DateTime } from 'luxon';
import { type FC, Fragment, useEffect, useState } from 'react';

interface Props extends PopoverContentProps {
  label?: string;
}
const apiClient = new TZServiceAPICLient();

export const LocalizedTimestampsPopover: FC<Props> = ({
  label = 'For relevant timezones',
  ...props
}) => {
  return (
    <Popover
      placement="bottom"
      content={<PopoverContent {...props} />}
      arrow={false}
      mouseEnterDelay={0.3}
    >
      <Label>{label}</Label>
    </Popover>
  );
};

const Label = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${RacemapColors.PaleBlue};
  cursor: pointer;

  :hover {
    color: ${RacemapColors.PaleBlue}aa;
  }
`;

interface TimestampEntry {
  timestamp: DateTime | null | undefined;
  label?: string;
}

interface PopoverContentProps {
  timestamps: Array<TimestampEntry>;
  showUTC?: boolean;
  showLocal?: boolean;
  showTimeAtPosition?: boolean;
  position?: { lat: number; lng: number };
}

const PopoverContent: FC<Props> = ({
  position,
  timestamps,
  showUTC = true,
  showLocal = true,
  showTimeAtPosition = true,
}) => {
  const [timezoneIdentifier, setTimezoneIdentifier] = useState<string | null>(null);

  useEffect(() => {
    if (position != null) {
      async function fetch() {
        if (position == null) return;

        const timezone = await apiClient.getTimezoneForLocation(position.lat, position.lng);

        setTimezoneIdentifier(timezone.name);
      }

      fetch();
    }
  }, [position]);

  const showHeader = timestamps.some((t) => t.label != null);
  const rows = [showHeader, showLocal, showUTC, showTimeAtPosition && position != null].reduce(
    (acc, cur) => (cur ? acc + 1 : acc),
    0,
  );
  const getTimestampColumn = ({ timestamp, label }: TimestampEntry) => {
    return (
      <Fragment key={`row-${label}`}>
        {showHeader && (
          <HeaderCell withTopBorder={false} key={`head-${label}`}>
            {label}
          </HeaderCell>
        )}
        {showUTC && (
          <Cell key={`utc-${label}`} title={hintUtc} withTopBorder={showHeader}>
            {timestamp?.toUTC().toLocaleString(DateTime.DATETIME_FULL)}
          </Cell>
        )}
        {showLocal && (
          <Cell key={`local-${label}`} title={hintLocal} withTopBorder={showHeader || showUTC}>
            {timestamp?.toLocal().toLocaleString(DateTime.DATETIME_FULL)}
          </Cell>
        )}
        {timezoneIdentifier && (
          <Cell key={`tz-${label}`} title={hintTimeAtPosition}>
            {timestamp?.setZone(timezoneIdentifier).toLocaleString(DateTime.DATETIME_FULL)}
          </Cell>
        )}
      </Fragment>
    );
  };

  return (
    <Table rows={rows}>
      <Fragment key="header">
        {showHeader && <HeaderCell withTopBorder={false} key="root" />}
        {showUTC && (
          <HeaderCell key="utc-head" title={hintUtc} withTopBorder={showHeader}>
            UTC
          </HeaderCell>
        )}
        {showLocal && (
          <HeaderCell key="local-head" title={hintLocal} withTopBorder={showHeader || showUTC}>
            Your Time
          </HeaderCell>
        )}
        {showTimeAtPosition && position != null && (
          <HeaderCell key="tz-head" title={hintTimeAtPosition}>
            Time at Event
          </HeaderCell>
        )}
      </Fragment>
      {timestamps.map(getTimestampColumn)}
    </Table>
  );
};

const hintUtc = 'Eventtimes in UTC';
const hintLocal =
  'Eventtimes are transferred to the time zone of the computer you are currently using.';
const hintTimeAtPosition =
  'Eventtimes in the timezone, in which the race takes place. Indicated by the first point of the shadowtrack.';

const Table = styled.div<{ rows: number }>`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-template-rows: ${({ rows }) => Array.from({ length: rows }, (_) => '1fr').join(' ')};
`;

const Cell = styled.div<{ withTopBorder?: boolean }>`
  border-top: ${({ withTopBorder: withBottomBorder = true }) =>
    withBottomBorder && `1px solid ${RacemapColors.LightGray}`};
  padding: 1px 7px;
`;

const HeaderCell = styled(Cell)<{ withBottomBorder?: boolean }>`
  font-weight: 600;
`;
