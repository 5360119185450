import { RacemapAPIClient, type ShowValues } from '@racemap/utilities/api-client';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import { Space } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import type { CSSProperties } from 'react';
import { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import { Button, FormControl, FormGroup, FormLabel, InputGroup, Media } from 'react-bootstrap';
import { EventLogo } from './BasicComponents/EventLogo';
import { IconPlus } from './Icon';

const apiClient = RacemapAPIClient.fromWindowLocation();

type SearchEventProps = {
  id?: string;
  value?: ReadonlyArray<{ id: string }>;
  label?: string;
  limit?: number;
  filter: string;
  disabled?: boolean;
  show?: Array<ShowValues>;
  ignoreList?: Array<string>;
  inputStyle?: CSSProperties;
  emptyAfterChoose?: boolean;
  onChange?: (events: Array<RacemapEvent>) => void;
  onSelect?: (event: RacemapEvent | null) => void;
  eventFilter?: (event: RacemapEvent) => boolean;
};

type SearchEventState = {
  textValue: string;
  suggestions: Array<RacemapEvent>;
  selectedObject: RacemapEvent;
};

class SearchEvent extends Component<SearchEventProps, SearchEventState> {
  state: SearchEventState = {
    textValue: '',
    selectedObject: null,
    suggestions: [],
  };

  static defaultProps = {
    value: [] as ReadonlyArray<{ id: string }>,
    id: 'SearchEvent',
    disabled: false,
    filter: 'can-read',
    show: ['hidden', 'atomiceventsonly'],
    limit: 10,
    ignoreList: [] as Array<string>,
    emptyAfterChoose: false,
  };

  getSuggestions = async (value: string) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    if (inputLength === 0) {
      return [];
    }

    const fetchedEventList = await apiClient.getEvents({
      limit: this.props.limit,
      filter: this.props.filter,
      show: this.props.show,
      findByPartOfName: inputValue,
    });

    const eventList = fetchedEventList.filter(this.props.eventFilter || (() => true));

    // remove from suggestlist choosen and ignore entries
    const suggestionsList = _.reject(eventList, (entry) => {
      const choosenValues = this.props.value.map((a) => a.id || a._id);
      const ignoreList = this.props.ignoreList;

      return choosenValues.concat(ignoreList).includes(entry.id);
    });

    return suggestionsList;
  };

  getSuggestionValue = (suggestion: RacemapEvent) => {
    this.setState({ selectedObject: suggestion });
    return suggestion.name;
  };

  renderSuggestion = (suggestion: RacemapEvent) => {
    return (
      <Media>
        <Space>
          <EventLogo eventId={suggestion.id} size={50} />
          <Media.Body>
            <p>
              <strong>{suggestion.name}</strong>
              <br />
              {moment(suggestion.startTime).format('DD.MM.')} -{' '}
              {moment(suggestion.endTime).format('DD.MM.YYYY')}
            </p>
          </Media.Body>
        </Space>
      </Media>
    );
  };

  renderInputComponent = (inputProps: Autosuggest.InputProps<RacemapEvent>) => {
    return (
      <FormGroup style={{ marginBottom: 'unset' }}>
        {this.props.label && <FormLabel>{this.props.label}</FormLabel>}
        {this.props.onChange == null ? (
          <FormControl {...inputProps} />
        ) : (
          <InputGroup>
            <FormControl {...inputProps} />
            <InputGroup.Append>
              <Button onClick={this.addEntry}>
                <IconPlus />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        )}
      </FormGroup>
    );
  };

  handleSuggestChange = (_event: React.ChangeEvent, { newValue }: { newValue: string }) => {
    if (newValue === '' && this.props.onSelect != null) {
      this.props.onSelect(null);
    }

    this.setState({
      textValue: newValue,
    });
  };

  addEntry = () => {
    const selObj = this.state.selectedObject;
    if (selObj != null && selObj !== '') {
      let newList = this.props.value;
      if (!newList.includes(this.state.selectedObject)) {
        newList = [...newList, this.state.selectedObject];
        if (this.props.onChange != null) {
          this.props.onChange(newList);
        }
        this.setState({ textValue: '', selectedObject: null });
      }
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = async ({ value }: { value: string }) => {
    this.setState({
      suggestions: await this.getSuggestions(value),
    });
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  // if you choose a suggestion, this function is called
  onSuggestionsSelected = (
    _event: React.ChangeEvent,
    { suggestion }: { suggestion: RacemapEvent },
  ) => {
    if (this.props.onSelect != null) {
      this.props.onSelect(suggestion);
    }

    if (this.props.emptyAfterChoose) {
      this.setState({
        textValue: '',
      });
    }
  };

  render() {
    const { textValue, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'Search for event name',
      value: textValue,
      onChange: this.handleSuggestChange,
      style: this.props.inputStyle,
    };

    // Finally, render it!
    return (
      <Autosuggest
        id={this.props.id}
        suggestions={suggestions}
        focusInputOnSuggestionClick={false}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionsSelected}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        renderInputComponent={this.renderInputComponent}
        inputProps={{ ...inputProps, disabled: this.props.disabled }}
      />
    );
  }
}

export default SearchEvent;
