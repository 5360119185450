import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { Button } from 'antd';
import { useState } from 'react';
import { Navigate } from 'react-router';
import { toast } from 'react-toastify';
import { useCurrentEvent } from 'src/lib/customHooks';
import { IconTrash } from './Icon';

const apiClient = RacemapAPIClient.fromWindowLocation();

const RemoveContestGroup = () => {
  const currentEvent = useCurrentEvent();
  const [isRemoving, setIsRemoving] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);

  const handleRemoveContestGroup = async () => {
    if (currentEvent == null) return;

    if (confirm('Do you really want to delete this contest group?')) {
      try {
        setIsRemoving(true);
        await apiClient.removeEvent(currentEvent.id);
        setIsRemoving(false);
        setIsRemoved(true);
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
        setIsRemoving(false);
      }
    }
  };

  if (isRemoved) {
    return <Navigate to="/admin" />;
  }

  return (
    <Button
      icon={<IconTrash />}
      danger
      type="primary"
      disabled={isRemoving}
      loading={isRemoving}
      onClick={() => handleRemoveContestGroup()}
    >
      Remove
    </Button>
  );
};

export default RemoveContestGroup;
