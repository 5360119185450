import type { RouteObject } from 'react-router-dom';
import { AdminRootView } from 'src/admin/views/AdminRootView';
import { EventEditorView } from 'src/admin/views/EventEditorView';
import { ErrorPage } from 'src/components/ErrorPage';
import { MonitorBetaView } from 'src/views/MonitorBetaView';
import { accountRoutes } from './account.routes';
import { billingRoutes } from './billing.routes';
import { eventEditorRoutes } from './event-editor.routes';
import { messageTemplatesRoutes } from './message-templates.routes';

export const adminRoutes: Array<RouteObject> = [
  {
    path: 'admin',
    element: <AdminRootView />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'events',
        lazy: async () => {
          const comp = await import('../admin/views/EventListView');
          return { Component: comp.EventListView };
        },
      },
      {
        path: 'events/manage',
        lazy: async () => {
          const comp = await import('../admin/views/ManageEventsView');
          return { Component: comp.ManageEventsView };
        },
      },
      {
        path: 'events/new',
        lazy: async () => {
          const comp = await import('../admin/views/EventCreatorView');
          return { Component: comp.EventCreatorView };
        },
      },
      {
        path: 'events/:eventId',
        element: <EventEditorView />,
        errorElement: <ErrorPage />,
        children: eventEditorRoutes,
      },
      {
        path: 'stagegroups/:stageGroupId/stages/new',
        lazy: async () => {
          const comp = await import('../admin/views/StageCreatorView');
          return { Component: comp.StageCreatorView };
        },
      },
      {
        path: 'users',
        lazy: async () => {
          const comp = await import('../admin/views/UserManagerView');
          return { Component: comp.UserManagerView };
        },
      },
      {
        path: 'trackers/*',
        lazy: async () => {
          const comp = await import('../admin/views/TrackerManagerView');
          return { Component: comp.TrackerManagerView };
        },
      },
      {
        path: 'contestgroups/new',
        lazy: async () => {
          const comp = await import('../admin/views/ContestGroupCreatorView');
          return { Component: comp.ContestGroupCreatorView };
        },
      },
      {
        path: 'stagegroups/new',
        lazy: async () => {
          const comp = await import('../admin/views/StageGroupCreatorView');
          return { Component: comp.StageGroupCreatorView };
        },
      },
      {
        path: 'predictive/emulation',
        lazy: async () => {
          const comp = await import('../admin/tpom_views/emulationView2');
          return { Component: comp.EmulationView };
        },
      },
      {
        path: 'track/:appId',
        lazy: async () => {
          const comp = await import('../admin/views/TrackDebugView');
          return { Component: comp.TrackDebugView };
        },
      },
      {
        path: 'sim-cards',
        lazy: async () => {
          const comp = await import('../admin/views/SimCardsView');
          return { Component: comp.SimCardsView };
        },
      },
      {
        path: 'monitor/beta/:eventSlug',
        element: <MonitorBetaView />,
        children: [
          {
            index: true,
            lazy: async () => {
              const comp = await import('../components/MonitorBeta/MonitorBeta');
              return { Component: comp.MonitorBeta };
            },
          },
        ],
      },
      ...messageTemplatesRoutes,
      ...accountRoutes,
      ...billingRoutes,
    ],
  },
];
