import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from './base';

export enum EventTypes {
  REGULAR = 'REGULAR',
  CONTEST = 'CONTEST',
  CONTEST_GROUP = 'CONTEST_GROUP',
  STAGE = 'STAGE',
  STAGE_GROUP = 'STAGE_GROUP',
}

export const EventOverviewSchema = z.object({
  id: ObjectIdSchema,
  name: z.string(),
  startTime: DateSchema,
  endTime: DateSchema,
  isRepeating: z.boolean(),
  isPredictive: z.boolean(),
  updatedAt: DateSchema,
  geo: z.object({
    shadowgeojsonHash: z.string().optional(),
  }),
  type: z.nativeEnum(EventTypes),
});

export type EventOverview = z.infer<typeof EventOverviewSchema>;

export const EventOverviewListSchema = z.array(EventOverviewSchema);

export type EventOverviewList = z.infer<typeof EventOverviewListSchema>;

export enum EventLoadTypes {
  MAP_LOADS = 'MAP',
  TIMING_LOADS = 'TIMING',
  MONITOR_LOADS = 'MONITOR',
  CURRENT_LOADS = 'CURRENT_API',
  TIMES_LOADS = 'TIMES_API',
  TIMES_ID_LOADS = 'TIMES_ID_API',
  RANKS_LOADS = 'RANKS_API',
  DISTANCE_LOADS = 'DISTANCE_API',
}

export enum SportTypes {
  RUNNING = 'running',
  TRAIL = 'trail',
  BIKING = 'biking',
  MTB = 'mtb',
  INLINE_SKATING = 'inline-skating',
  SWIMMING = 'swimming',
  TRIATHLON = 'triathlon',
  EQUESTRIAN_SPORT = 'equestrian',
  ORIENTEERING = 'orienteering',
  ROWING = 'rowing',
  SAILING = 'sailing',
  CANOEING = 'canoeing',
  MOTORBOAT = 'motorboat',
  CYCLING = 'cycling',
  SUP = 'sup',
  SKIING = 'skiing',
  MOTOCROSS = 'motocross',
  RALLY_CAR = 'rally-car',
  HIKING = 'hiking',
  DUATHLON = 'duathlon',
  TRANSITION_AREA = 'transition_area',
  OTHER = 'other',
}

export const EventTypeSchema = z.object({
  id: z.nativeEnum(SportTypes),
  name: z.string(),
  description: z.string(),
  symbol: z.string(),
  defaultSpeed: z.number(),
  maxSpeed: z.number(),
  minSpeed: z.number(),
  onlySplit: z.boolean(),
});

export type EventType = z.infer<typeof EventTypeSchema>;
