export enum StripeProducts {
  // one usage product
  BASE_PRICE = 'base_price',

  // Event Products
  MAP = 'map',
  MAP_OR_MONITOR_LOAD = 'map_or_monitor_load',
  API_OR_LEADERBOARD_LOAD = 'api_or_leaderboard_load',
  GPS_DEVICE = 'gps_device',
  TRANSPONDER = 'transponder',
  KEY = 'key',
  SPONSOR_ADDON = 'sponsor_addon',
  TIMING_ADDON = 'timing_addon',
  MONITOR_ADDON = 'monitor_addon',
  API_ADDON = 'api_addon',

  // SIM Card Products
  DATA_USAGE = 'data_usage',
  SIM_CARD_ACTIVATION = 'sim_card_activation',

  // Trackermanagment Products
  TRACKER_MANAGEMENT = 'tracker_management',
  SMS = 'sms',

  // Custom Products
  CUSTOM_APP = 'custom_app',

  // Rent Tracker
  TRACKER_RENTAL = 'tracker_rental',

  // legacy products
  DATA_USAGE_ZONE_1 = 'data_usage_zone_1',
  DATA_USAGE_ZONE_2 = 'data_usage_zone_2',
  DATA_USAGE_ZONE_3 = 'data_usage_zone_3',
  PAGE_VIEW = 'page_view',
  STARTER = 'starter',
}
