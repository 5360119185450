import styled from '@emotion/styled';
import { TZServiceAPICLient } from '@racemap/utilities/api-client';
import { shortIdBuilder } from '@racemap/utilities/functions/utils';
import { Popover } from 'antd';
import { DateTime } from 'luxon';
import { type FC, type PropsWithChildren, useEffect, useState } from 'react';
import { type TimeVariantInput, castDateTime } from '../../utils/timeUtils';
import { HStack } from '../MetaComponent';

type Props = {
  datetime: TimeVariantInput | null | undefined;
  hint?: string;
  tooltipPosition?: 'top' | 'right' | 'bottom' | 'left';
  localizeTimeLocation?: [number, number] | null;
};

const apiClient = new TZServiceAPICLient();

export const TooltipWithUTCDatetime: FC<PropsWithChildren<Props>> = ({
  children,
  datetime,
  hint,
  localizeTimeLocation,
  tooltipPosition = 'top',
}) => {
  const id = shortIdBuilder();
  const [timezoneIdentifier, setTimezoneIdentifier] = useState<string | null>(null);
  const parsedDateTime = datetime != null ? castDateTime(datetime) : null;

  useEffect(() => {
    if (localizeTimeLocation != null) {
      async function fetch() {
        if (localizeTimeLocation == null) return;

        const timezone = await apiClient.getTimezoneForLocation(
          localizeTimeLocation[0],
          localizeTimeLocation[1],
        );

        setTimezoneIdentifier(timezone.name);
      }

      fetch();
    }
  }, [localizeTimeLocation]);

  if (children == null) return <></>;
  if (parsedDateTime == null) return <>{children}</>;

  return (
    <Popover
      id={id}
      title={
        <HStack>
          <TimestampsTable>
            <tbody>
              <tr>
                <td>
                  <strong>UTC:</strong>
                </td>
                <td>{parsedDateTime.toUTC().toLocaleString(DateTime.DATETIME_FULL)}</td>
              </tr>
              <tr>
                <td>
                  <strong>Your Time:</strong>
                </td>
                <td>{parsedDateTime.toLocal().toLocaleString(DateTime.DATETIME_FULL)}</td>
              </tr>
              {hint ? (
                <tr>
                  <td>
                    <strong>Note:</strong>
                  </td>
                  <td>{hint}</td>
                </tr>
              ) : null}
              {timezoneIdentifier != null ? (
                <tr>
                  <td>
                    <strong>Time at Event:</strong>
                  </td>
                  <td>
                    {parsedDateTime
                      .setZone(timezoneIdentifier)
                      .toLocaleString(DateTime.DATETIME_FULL)}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </TimestampsTable>
        </HStack>
      }
      placement={tooltipPosition}
    >
      {children}
    </Popover>
  );
};

export const HStackBaseline = styled(HStack)`
  align-items: baseline;
  max-width: 500px;
`;

const TimestampsTable = styled.table`
  text-align: right;
  line-height: 1.7;

  tdr:nth-child(2) {
    padding-left: 10px;
  }
`;
