import type { HydratedDocument } from 'mongoose';
import { z } from 'zod';
import { DateSchema, ObjectIdSchema } from './base';
import { TRACKER_TYPES, USER_ROLE } from './trackers';
import type { UserDocument } from './user';

export enum MessageTemplateVariant {
  SINGLE = 'SINGLE',
  PIPELINE = 'PIPELINE',
  GROUP = 'GROUP',
}

export enum MessageTemplateArgumentVariant {
  VARIABLE = 'VARIABLE',
  PARAMETER = 'PARAMETER',
  FIXED = 'FIXED',
}

export enum MessageTemplateArgumentDataType {
  TEXT = 'text',
  NUMBER = 'number',
  PASSWORD = 'password',
  CHECKBOX = 'checkbox',
  SELECT = 'select',
}

export enum VARIABLEN_NAMES {
  MESSAGE_COUNT_INT = 'MESSAGE_COUNT_INT',
  MESSAGE_COUNT_HEX = 'MESSAGE_COUNT_HEX',
  TRACKER_PASSWORD = 'TRACKER_PASSWORD',
  TRACKER_ID = 'TRACKER_ID',
  TRACKER_NAME = 'TRACKER_NAME',
  RANDOM_NUMBER = 'RANDOM_NUMBER',
  RANDOM_NUMBER_HEX = 'RANDOM_NUMBER_HEX',
  MT_90_MESSAGE_LENGTH = 'MT_90_MESSAGE_LENGTH',
  MT_90_CHECKSUM = 'MT_90_CHECKSUM',
}

export const MessageTemplateArgumentSchema = z.object({
  id: ObjectIdSchema,
  name: z.string(),
  variant: z.nativeEnum(MessageTemplateArgumentVariant),
  dataType: z.nativeEnum(MessageTemplateArgumentDataType).optional(),
  value: z.string().optional(),
  default: z.string().optional(),
  optional: z.boolean().optional(),
  description: z.string().optional(),
  options: z.array(z.object({ key: z.string(), label: z.string(), value: z.string() })).optional(),
  createdAt: DateSchema,
  updatedAt: DateSchema,
});

export const responseIdArgumentNames = [
  VARIABLEN_NAMES.MESSAGE_COUNT_HEX,
  VARIABLEN_NAMES.MESSAGE_COUNT_INT,
  VARIABLEN_NAMES.RANDOM_NUMBER,
  VARIABLEN_NAMES.RANDOM_NUMBER_HEX,
];

export type MessageTemplateArgument = z.infer<typeof MessageTemplateArgumentSchema>;

export type MessageTemplateArgumentDocument = HydratedDocument<MessageTemplateArgument>;

export const MessageTemplateArgumentPrototypeSchema = MessageTemplateArgumentSchema.omit({
  id: true,
  createdAt: true,
  updatedAt: true,
});

export type MessageTemplateArgumentPrototype = z.infer<
  typeof MessageTemplateArgumentPrototypeSchema
>;

export const MessageTemplateArgumentVariableSchema = MessageTemplateArgumentSchema.omit({
  default: true,
  optional: true,
  dataType: true,
}).extend({
  value: z.string(),
});

export type MessageTemplateArgumentVariable = z.infer<typeof MessageTemplateArgumentVariableSchema>;

export const MessageTemplateSchema = z.object({
  id: ObjectIdSchema,
  template: z.string(),
  arguments: z.array(MessageTemplateArgumentSchema),
  name: z.string().min(3, { message: 'Template name must be at least 3 characters long' }),
  variant: z.nativeEnum(MessageTemplateVariant),
  description: z.string(),
  trackerTypes: z.array(z.nativeEnum(TRACKER_TYPES)),
  creatorId: ObjectIdSchema,
  updaterId: ObjectIdSchema,
  createdAt: DateSchema,
  updatedAt: DateSchema,
  editors: z.array(ObjectIdSchema),
  viewers: z.array(ObjectIdSchema),
  isPublic: z.boolean(),
  subTemplates: z.array(ObjectIdSchema),
  allowedTrackerUserRoles: z.array(z.nativeEnum(USER_ROLE)),
});

export type MessageTemplate = z.infer<typeof MessageTemplateSchema>;

export const MessageTemplatePrototypeSchema = MessageTemplateSchema.omit({
  createdAt: true,
  updatedAt: true,
  updaterId: true,
  creatorId: true,
})
  .partial()
  .required({
    name: true,
  })
  .extend({
    arguments: z.array(MessageTemplateArgumentPrototypeSchema).optional(),
  });

export type MessageTemplatePrototype = z.infer<typeof MessageTemplatePrototypeSchema>;

export interface MessageTemplateDocumentMethods {
  canEdit(user: UserDocument | null | undefined): Promise<boolean>;
  canRead(user: UserDocument | null | undefined): Promise<boolean>;
}

export type MessageTemplateDocument = HydratedDocument<
  MessageTemplate,
  MessageTemplateDocumentMethods
>;

export const MessageTemplateGroupSchema = MessageTemplateSchema.omit({
  template: true,
  arguments: true,
  trackerTypes: true,
  subTemplates: true,
}).extend({
  subTemplates: z.array(ObjectIdSchema),
  variant: z.literal(MessageTemplateVariant.GROUP),
});

export type MessageTemplateGroup = z.infer<typeof MessageTemplateGroupSchema>;
