import { DatePicker } from 'antd';
import type { DateTime } from 'luxon';
import luxonGenerateConfig from 'rc-picker/lib/generate/luxon';
import { calculateDisabledDate } from './calculateDisabledDate';
import { calculateDisabledTime } from './calculateDisabledTime';

const CustomDatePicker = DatePicker.generatePicker<DateTime>(luxonGenerateConfig);
type RangePickerProps = React.ComponentProps<typeof CustomDatePicker>;

type Props = RangePickerProps & {
  minDate?: DateTime;
};

const DatePickerComponent: React.FC<Props> = ({ minDate, maxDate, ...props }) => {
  return (
    <CustomDatePicker
      {...props}
      disabledTime={(current) => calculateDisabledTime({ current, minDate, maxDate })}
      disabledDate={(current) => calculateDisabledDate({ current, minDate, maxDate })}
    />
  );
};

export default DatePickerComponent;
