import CheckmarkAnimationUrl from '@assets/checkmark-animation.gif';
import styled from '@emotion/styled';
import { RacemapAPIClient } from '@racemap/utilities/api-client';
import { RacemapColors } from '@racemap/utilities/consts/common';
import { EventTypes, VisibilityStates } from '@racemap/utilities/consts/events';
import { isGroupEvent, isNotEmptyString } from '@racemap/utilities/functions/utils';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import { Button, Card, Space } from 'antd';
import humanizeDuration from 'humanize-duration';
import type { Immutable } from 'immer';
import { DateTime } from 'luxon';
import { type FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import type { CurrentEvent } from 'src/store/events/events_reducers';
import { DateRangePicker } from '../BasicComponents/DateRangePicker';
import { HStack } from '../BasicComponents/MetaComponent';
import { Bold } from '../BasicComponents/MetaComponent/MetaComponent';
import { Tristate } from '../ButtonWithSpinner';
import { FormCheck } from '../FormComponents';
import { IconCopy, IconFail } from '../Icon';
import { NameFieldGroup } from './NameFieldGroup';

type Props = {
  event: Immutable<CurrentEvent>;
};

const apiClient = RacemapAPIClient.fromWindowLocation();

export const EventDuplicateModal: FC<Props> = ({ event }) => {
  const nextHourDate = DateTime.local().startOf('hour').plus({ hours: 1 });
  const [newEventName, setNewEventName] = useState(`${event.name} (Copy)` || '');
  const [show, setShow] = useState(false);
  const [savingState, setSavingState] = useState(Tristate.NORMAL);
  const [newStartTime, setNewStartTime] = useState(nextHourDate);
  const [newEndTime, setNewEndTime] = useState(nextHourDate.plus({ hours: 1 }));
  const [withStarters, setWithStarters] = useState(false);
  const [withGeoElements, setWithGeoElements] = useState(false);
  const [copyToGroup, setCopyToGroup] = useState(false);
  const [duplicatedEvent, setDuplicatedEvent] = useState<RacemapEvent | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.endsWith('/duplicate')) {
      setShow(true);
    }
  }, [location.pathname]);

  const inputValuesAreValid =
    newEventName !== event.name &&
    isNotEmptyString(newEventName) &&
    newStartTime.isValid &&
    newEndTime.isValid;
  const handleClose = () => {
    setDuplicatedEvent(null);
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const handleDateRangeChange = (values: [DateTime | null, DateTime | null] | null) => {
    if (!values?.[0] || !values?.[1]) {
      toast.error('Invalid dates');
      return;
    }

    setNewStartTime(values[0]);
    setNewEndTime(values[1]);
  };

  const handleCopyEvent = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSavingState(Tristate.SPINNING);

    const startTimeString = newStartTime.toISO();
    const endTimeString = newEndTime.toISO();

    if (startTimeString == null || endTimeString == null) {
      toast.error('Invalid input date.');
      return;
    }

    const newProps: Partial<RacemapEvent> = {
      startTime: startTimeString,
      endTime: endTimeString,
      name: newEventName,
      visibility: VisibilityStates.UNLISTED,
    };

    if (!copyToGroup && !isGroupEvent(event)) {
      newProps.type = EventTypes.REGULAR;
      newProps.parent = null;
    }

    setDuplicatedEvent(
      await apiClient.duplicateEvent(event.id, { withStarters, withGeoElements }, newProps),
    );
    setSavingState(Tristate.NORMAL);
  };

  return (
    <>
      <Button icon={<IconCopy />} onClick={handleShow}>
        Duplicate Tracking Map
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleCopyEvent}>
          <Modal.Header closeButton>
            <Modal.Title>
              {duplicatedEvent == null
                ? 'Duplicate Tracking Map'
                : 'Event successfully duplicated.'}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {duplicatedEvent == null ? (
              <>
                <NameFieldGroup
                  value={newEventName}
                  onChange={setNewEventName}
                  oldEventName={`${event.name}`}
                />
                <StyledRangePicker
                  showTime={{ format: 'HH:mm:ss' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  value={[newStartTime, newEndTime]}
                  onChange={handleDateRangeChange}
                />
                {newEndTime && newStartTime && (
                  <DurationCard>
                    <HStack justify="space-around" wrap="wrap">
                      <Space>
                        <Bold>Duration:</Bold>

                        {humanizeDuration(
                          newEndTime
                            .diff(newStartTime, ['month', 'day', 'hour', 'minute', 'second'])
                            .valueOf(),
                        )}
                      </Space>
                    </HStack>
                  </DurationCard>
                )}

                <FormCheck
                  id="starterCheckbox"
                  label="With participants"
                  value={withStarters}
                  onChange={() => {
                    setWithStarters((value) => !value);
                  }}
                />

                <FormCheck
                  id="geoElementsCheckbox"
                  label="With tracks, pois and splits"
                  value={withGeoElements}
                  onChange={() => {
                    setWithGeoElements((value) => !value);
                  }}
                />

                {(event.type === EventTypes.CONTEST || event.type === EventTypes.STAGE) && (
                  <FormCheck
                    id="groupCheckbox"
                    label={`Duplicate into current ${
                      event.type === EventTypes.CONTEST ? 'group of single maps' : 'group of stages'
                    }`}
                    value={copyToGroup}
                    onChange={() => {
                      setCopyToGroup(!copyToGroup);
                    }}
                  />
                )}
              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={CheckmarkAnimationUrl}
                  alt="Checkmark animation"
                  style={{ width: 200, height: 200 }}
                />
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              icon={<IconFail />}
              onClick={handleClose}
              className="racemap_duplicate_event_modal_close"
            >
              Close
            </Button>
            {duplicatedEvent == null ? (
              <Button
                icon={<IconCopy />}
                loading={savingState === Tristate.SPINNING}
                disabled={!inputValuesAreValid}
                htmlType="submit"
                type="primary"
              >
                Duplicate Tracking Map
              </Button>
            ) : (
              <Link to={`/admin/events/${duplicatedEvent.id}`}>
                <Button>Go to duplicated event</Button>
              </Link>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

const StyledRangePicker = styled(DateRangePicker)`
  width: 100%;
`;

const DurationCard = styled(Card)`
  margin: 15px 0 15px 0;
  border-color: ${RacemapColors.PaleBlue};
  max-width: 1000px;

  .ant-card-body{
    padding: 5px;
  }
`;
