const isNode = process?.release?.name === 'node';

const fetchWithKeepAlive = (() => {
  if (isNode) {
    const http = require('node:http');
    const https = require('node:https');

    // keepAlive causes the prediction test http calls to exeed the timeouts cause the connection does not return properly
    const httpAgent = new http.Agent({
      keepAlive: true,
    });
    const httpsAgent = new https.Agent({
      keepAlive: true,
    });

    return function fetchWithKeepAlive(
      input: RequestInfo,
      init: RequestInit = {},
    ): Promise<Response> {
      return fetch(input, {
        ...init,
        // @ts-expect-error cross fetch implement the types of node fetch not correctly
        agent: (_parsedURL) => (_parsedURL.protocol === 'http:' ? httpAgent : httpsAgent),
      });
    };
  }
  return fetch;
})();

export default fetchWithKeepAlive;
