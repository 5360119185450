import { RacemapAPIClient } from '@racemap/utilities/api-client';
import {
  AuthorizationStates,
  EventTypes,
  VisibilityStates,
} from '@racemap/utilities/consts/events';
import { getSplitsOfTrack, isActivated } from '@racemap/utilities/functions/event';
import {
  type Geofence,
  type PreparedRanksPackage,
  RanksQueryFrom,
  type TagsCollection,
} from '@racemap/utilities/functions/timing';
import { isGroupEvent } from '@racemap/utilities/functions/utils';
import { isChildEvent } from '@racemap/utilities/functions/utils';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import type { Splits } from '@racemap/utilities/types/types';
import { type Immutable, produce } from 'immer';
import type { StoreApi } from 'zustand';
import type { DraftState, State } from '../reducers';
import { prepareStarterInfo } from './leaderboard_helper';

const apiClient = RacemapAPIClient.fromWindowLocation(false);

export interface EventData {
  eventObject: RacemapEvent;
  splits: Splits;
  tags: TagsCollection;
  starterData: PreparedRanksPackage;
  timekeepings: Array<Geofence>;
}

export interface LeaderboardState {
  leaderboard: {
    events: Map<string, EventData>;
    isLoading: boolean;
    selectedEventId: string | null;
    parentEventId: string | null;
    selectedEventSplits: Splits | null;
    actions: {
      loadEventData: (slug: string) => Promise<void>;
      changeSelectedEvent: (eventId: string | null) => Promise<void>;
      clearEventStore: () => void;
    };
    getter: {
      relevantEventsData: () => Immutable<Map<string, Immutable<EventData>>>;
      selectedEventData: () => Immutable<EventData> | null;
      parentEventData: () => Immutable<EventData> | null;
    };
  };
}

export const createLeaderboardStore = (
  set: StoreApi<State>['setState'],
  get: StoreApi<State>['getState'],
): LeaderboardState => ({
  leaderboard: {
    events: new Map(),
    isLoading: false,
    parentEventId: null,
    selectedEventId: null,
    selectedEventSplits: null,
    actions: {
      clearEventStore: () => {
        set(
          produce((s: DraftState) => {
            s.leaderboard.events.clear();
          }),
        );
      },
      loadEventData: async (slug) => {
        set(
          produce((s: DraftState) => {
            s.leaderboard.isLoading = true;
          }),
        );

        const mainEvent = await apiClient.getEvent(slug);
        let events: Array<RacemapEvent> = [mainEvent];
        const selectedEventId = get().leaderboard.selectedEventId || mainEvent.id;
        const selectedEventSplits = await apiClient.getEventSplits(selectedEventId);

        switch (mainEvent.type) {
          case EventTypes.CONTEST_GROUP:
          case EventTypes.STAGE_GROUP: {
            const childEvents = await apiClient.getEvents({
              findByParentId: mainEvent.id,
              show: ['hidden'],
            });
            if (childEvents.length === 0) break;

            events.push(...childEvents);
            break;
          }
          case EventTypes.CONTEST:
          case EventTypes.STAGE: {
            if (mainEvent.parent == null) throw new Error('Event of group has no parent id!');

            const [allChildEvents, parentEvent] = await Promise.all([
              apiClient.getEvents({ findByParentId: mainEvent.parent, show: ['hidden'] }),
              apiClient.getEvent(mainEvent.parent),
            ]);

            events = [...allChildEvents, parentEvent];
            break;
          }
        }
        const activeEvents = events.filter(
          (e) =>
            (!isChildEvent(e) || e.authorization !== AuthorizationStates.NONE) &&
            e.visibility !== VisibilityStates.ARCHIVED &&
            isActivated(e.modules.timing) &&
            (!isChildEvent(e) || e.visibility !== VisibilityStates.UNLISTED) &&
            e.type !== EventTypes.CONTEST_GROUP,
        );

        const eventsData = await Promise.all(
          activeEvents.map(async (e) => {
            const furtherData = await getFurtherEventData(e);

            return {
              eventObject: e,
              ...(furtherData || {}),
            };
          }),
        );

        // if we have a group event in the events, we set the allowed tags based on the child allowed tags
        const allowedTags = new Set(
          activeEvents.reduce<Array<string>>(
            (pS, event) => pS.concat(event.playerOptions.filterTagKeys || []),
            [],
          ),
        );
        const groupEvent = activeEvents.find((e) => isGroupEvent(e));
        if (groupEvent != null)
          groupEvent.playerOptions.filterTagKeys =
            allowedTags.size > 0 ? Array.from(allowedTags) : null;

        set(
          produce((s: DraftState) => {
            for (const data of eventsData) {
              if (data.eventObject.id === selectedEventId) {
                data.splits = selectedEventSplits;
              }
              s.leaderboard.events.set(data.eventObject.id, data);
            }

            if (s.leaderboard.selectedEventId == null) {
              s.leaderboard.selectedEventId = mainEvent.id;
            }
            s.leaderboard.isLoading = false;
          }),
        );
      },
      changeSelectedEvent: async (eventId) => {
        set(
          produce((s: DraftState) => {
            s.leaderboard.selectedEventId = eventId;
          }),
        );
      },
    },
    getter: {
      relevantEventsData: () => {
        const relevantEventsData: Map<string, Immutable<EventData>> = new Map();
        const leaderboardStore = get().leaderboard;
        const allEvents = Array.from(leaderboardStore.events.values());

        for (const e of allEvents) {
          relevantEventsData.set(e.eventObject.id, e);
        }

        return relevantEventsData;
      },
      selectedEventData: () => {
        const leaderboardStore = get().leaderboard;
        const selectedEventId = leaderboardStore.selectedEventId;
        if (selectedEventId == null) return null;
        const selectedEventData = leaderboardStore.events.get(selectedEventId) || null;

        return selectedEventData;
      },
      parentEventData: () => {
        const selectedEventData = get().leaderboard.getter.selectedEventData();
        if (selectedEventData?.eventObject.type === EventTypes.STAGE_GROUP)
          return selectedEventData;
        if (selectedEventData == null || selectedEventData.eventObject.parent == null) return null;

        const parentData = get().leaderboard.events.get(selectedEventData.eventObject.parent);
        return parentData || null;
      },
    },
  },
});

async function getFurtherEventData(event: Immutable<RacemapEvent>): Promise<{
  starterData: PreparedRanksPackage;
  timekeepings: Array<Geofence>;
  tags: TagsCollection;
  splits: Splits;
}> {
  try {
    if (event.type === EventTypes.CONTEST_GROUP)
      return {
        starterData: {
          name: event.name,
          startTime: event.startTime,
          endTime: event.endTime,
          geoJSON: '',
          location: event.location,
          image: '',
          timekeepings: [],
          starters: [],
          tagsStarterCount: new Map(),
        },
        timekeepings: [],
        tags: new Map(),
        splits: [],
      };

    const starterData = await apiClient.getStarterRanks(event.id, RanksQueryFrom.LEADERBOARD);
    let tagsCollected = new Map();
    let preparedStarterData: PreparedRanksPackage = {
      ...starterData,
      starters: [],
      tagsStarterCount: new Map(),
    };

    const splits = getSplitsOfTrack(event || null);

    // reduce the tags object of every starter, calculate rank and count starter of every tag
    const sortedStarters = starterData.starters.sort((s1, s2) => {
      if (s1.rank === -1) return 1;
      if (s2.rank === -1) return -1;

      return s1.rank - s2.rank;
    });

    for (const s of sortedStarters) {
      const preparedStarterInfoCol = prepareStarterInfo(
        event,
        s,
        tagsCollected,
        preparedStarterData,
      );

      const preparedStarter = preparedStarterInfoCol.preparedStarter;
      tagsCollected = preparedStarterInfoCol.tagsCollected;
      preparedStarterData = preparedStarterInfoCol.preparedStarterData;

      preparedStarterData.starters.push(preparedStarter);
    }

    const tagsPrepared = new Map();
    for (const [tagKey, tagValues] of tagsCollected.entries()) {
      tagsPrepared.set(
        tagKey,
        Array.from(tagValues).map((t) => ({ value: t })),
      );
    }

    return {
      starterData: preparedStarterData,
      timekeepings: starterData?.timekeepings,
      tags: tagsPrepared,
      splits,
    };
  } catch (e) {
    console.error(e);
    return {
      starterData: {
        name: event.name,
        startTime: event.startTime,
        endTime: event.endTime,
        geoJSON: '',
        location: event.location,
        image: '',
        timekeepings: [],
        starters: [],
        tagsStarterCount: new Map(),
      },
      timekeepings: [],
      tags: new Map(),
      splits: [],
    };
  }
}
