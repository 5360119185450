import { z } from 'zod';
import { ObjectIdSchema } from './base';

export const TagEntry = z.object({
  value: z.string(),
  label: z.string().optional(),
  count: z.number().optional(),
});

export type TagEntry = z.infer<typeof TagEntry>;

export const TagsSchema = z.map(z.string(), z.array(TagEntry));

export type TagsCollection = z.infer<typeof TagsSchema>;

export const StarterSchema = z.object({
  id: ObjectIdSchema,
  name: z.string(),
  appId: z.string().optional(),
  startNumber: z.string(),
  deviceClass: z.string().nullable(),
  deviceType: z.string().nullable(),
});

export type Starter = z.infer<typeof StarterSchema>;
