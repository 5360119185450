import { type ObjectId, ObjectIdSchema } from '@racemap/sdk/schema/base';
import { isAtomicEvent } from '@racemap/utilities/functions/utils';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import { Modal, Space } from 'antd';
import Search from 'antd/es/input/Search';
import type { DataNode } from 'antd/es/tree';
import type { Immutable } from 'immer';
import { type FC, useEffect, useState } from 'react';
import { getTypeBadge } from '../../../lib/EventUtils';
import { useStore } from '../../../store/reducers';
import { prepareFuse } from '../../utils/prepareFuse';
import { AddTrackerToEvent } from '../AddTrackerToEvent/AddTrackerToEvent';
import { type TrackerDropItem, TrackerDropZone } from '../TrackerDropZone';
import { Filters } from './FilterTreeFunctions';
import { draggableDataNode } from './nodes';
import type { DataNodeProps } from './prepareFilterSubTreeComponentMapping';

export const NO_EVENT_KEY = 'none';

export const EventsNode = (props: DataNodeProps): DataNode => {
  const { key, title } = props;
  const events = useStore((s) => s.trackers.events);
  const [searchValue, setSearchValue] = useState('');
  const eventIds = [NO_EVENT_KEY, ...Array.from(events.values()).map((e) => e.id)];
  const filterCounts = useStore((s) => s.trackers.filterCounts);
  const counts = filterCounts.get(Filters.Events);

  const eventsArray = Array.from(events.values()).map((event) => ({
    id: event.id,
    name: event.name || '',
  }));

  const fuseOptions = {
    keys: ['name'],
    threshold: 0.3,
  };

  const { setCollection, search } = prepareFuse<{ id: string; name: string }>(
    eventsArray,
    fuseOptions,
  );

  useEffect(() => {
    setCollection(eventsArray);
  }, [eventsArray]);

  const filteredEvents = searchValue
    ? eventIds
        .filter((eId) => eId === searchValue)
        .concat(search(searchValue).map((result) => result.item.id))
    : eventIds;

  const eventsNodes = filteredEvents.map((eId) => {
    const event = events.get(eId);
    return draggableDataNode(<EventLabel event={event} />, `${key}/${eId}`, counts?.get(eId));
  });

  return {
    title: title,
    key: key,
    disableCheckbox: true,
    children: [
      {
        title: (
          <Search
            allowClear
            placeholder="Search"
            size="small"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        ),
        key: `${key}/search`,
        disabled: true,
      },
      ...(eventsNodes as DataNode[]),
    ],
  };
};

const EventLabel: FC<{ event?: Immutable<RacemapEvent> }> = ({ event }) => {
  const [open, setOpen] = useState(false);
  const [trackerIds, setTrackerIds] = useState<Array<ObjectId>>([]);

  if (!event) return <>{NO_EVENT_KEY}</>;

  return !isAtomicEvent(event) ? (
    <Space>
      {event.name || event.id}
      {getTypeBadge(event.type)}
    </Space>
  ) : (
    <TrackerDropZone
      acceptedElementIds={['sample-trackers', 'total-trackers']}
      withIsOver={true}
      onDrop={(item) => {
        setTrackerIds(
          Array.from((item as TrackerDropItem).data.ids).map((id) => ObjectIdSchema.parse(id)),
        );
        setOpen(true);
      }}
    >
      <Space>
        {event.name || event.id}
        {getTypeBadge(event.type)}
      </Space>
      <Modal
        title="Add tracker to an event"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <AddTrackerToEvent
          trackerIds={trackerIds}
          event={event}
          onAddToEvent={() => setOpen(false)}
        />
      </Modal>
    </TrackerDropZone>
  );
};
