import { ObjectId } from '@racemap/sdk/schema/base';
import { VisibilityStates } from '@racemap/utilities/consts/events';
import { compareNumber } from '@racemap/utilities/functions/compareNumber';
import { isArchivedEvent, isNotActivatedEvent } from '@racemap/utilities/functions/event';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import type { Immutable } from 'immer';
import { useCurrentEvent } from '../lib/customHooks';
import { useStore } from '../store/reducers';
import { EventList } from './EventList';

export const GroupEventList: React.FC = () => {
  const contestGroup = useCurrentEvent();
  const groupEvents = useStore((s) => s.events.getter.childEvents());
  const { removeContests, updateEvent, swapChildEvents } = useStore((s) => s.events.actions);
  const groupEventsSorted = [...groupEvents].sort((eA, eB) => compareNumber(eA.index, eB.index));

  if (contestGroup == null) return null;

  const handleEventRemove = async (event: Immutable<RacemapEvent>) => {
    await removeContests([new ObjectId(event.id)], new ObjectId(contestGroup.id));
  };

  const handleVisibilityToggle = async (event: Immutable<RacemapEvent>) => {
    if (isNotActivatedEvent(event) || isArchivedEvent(event)) return;

    const newVisibility =
      event.visibility === VisibilityStates.LISTED
        ? VisibilityStates.UNLISTED
        : VisibilityStates.LISTED;

    await updateEvent(event.id, [{ key: 'visibility', newValue: newVisibility }]);
  };

  return (
    <EventList
      events={groupEventsSorted}
      onSwapEvents={swapChildEvents}
      onEventRemove={handleEventRemove}
      onVisibilityToggle={handleVisibilityToggle}
    />
  );
};
