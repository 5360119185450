import styled from '@emotion/styled';
import { VisibilityStates } from '@racemap/utilities/consts/events';
import { isHiddenChildEvent } from '@racemap/utilities/functions/event';
import {
  eventIsNeitherFreeNorPaid,
  isChildEvent,
  isGroupEvent,
} from '@racemap/utilities/functions/utils';
import type { RacemapEvent } from '@racemap/utilities/types/events';
import { Space, Tag } from 'antd';
import type { Immutable } from 'immer';
import { DateTime } from 'luxon';
import type { FC } from 'react';
import { useCurrentUser } from '../../lib/customHooks';
import { IconFuture, IconInvisible, IconOk, IconPast, IconPlay, IconVisible } from '../Icon';

interface BadgesProps {
  event:
    | Pick<
        Immutable<RacemapEvent>,
        'startTime' | 'endTime' | 'authorization' | 'visibility' | 'type'
      >
    | undefined;
}

export const EventInfoBadges: FC<BadgesProps> = ({ event }) => {
  const user = useCurrentUser();
  if (user == null) return <></>;

  return (
    <Container>
      <RunningBadge event={event} />
      <PaymentStateBadge event={event} />
      <ArchivedBadge event={event} />
      {isChildEvent(event) && <GroupVisibilityBagde event={event} />}
    </Container>
  );
};

export const ArchivedBadge: FC<BadgesProps> = ({ event }) => {
  if (event == null) return null;
  if (event.visibility !== VisibilityStates.ARCHIVED) return null;

  return <Tag color="volcano">ARCHIVED</Tag>;
};

export const GroupVisibilityBagde: FC<BadgesProps> = ({ event }) => {
  if (event == null) return null;
  const isHidden = isHiddenChildEvent(event);

  return (
    <Tag icon={isHidden ? <IconInvisible /> : <IconVisible />} color="purple">
      {isHidden ? 'Hidden' : 'Visible'}
    </Tag>
  );
};

export const RunningBadge: FC<BadgesProps> = ({ event }) => {
  if (event == null) return null;
  if (event.startTime == null || event.endTime == null) return null;
  if (event.visibility === VisibilityStates.ARCHIVED) return null;

  const start = DateTime.fromISO(event.startTime);
  const end = DateTime.fromISO(event.endTime);

  if (DateTime.now() < start)
    return (
      <Tag icon={<IconFuture />} color="processing" bordered={false}>
        UPCOMING
      </Tag>
    );
  if (DateTime.now() > start && DateTime.now() < end)
    return (
      <Tag icon={<IconPlay />} color="success">
        LIVE
      </Tag>
    );
  if (DateTime.now() > end) return <Tag icon={<IconPast />}>FINISHED</Tag>;

  return null;
};

export const PaymentStateBadge: FC<BadgesProps> = ({ event }) => {
  if (event == null) return null;
  if (isGroupEvent(event)) return null;
  if (eventIsNeitherFreeNorPaid(event)) return <Tag color="warning">TRIAL</Tag>;
  if (event.visibility === VisibilityStates.ARCHIVED) return null;

  return (
    <Tag icon={<IconOk />} color="success">
      ACTIVE
    </Tag>
  );
};

const Container = styled(Space)`
  flex-wrap: wrap;

  .ant-tag {
    margin-inline-end: unset;
  }

  svg {
    margin-right: 4px;
  }
`;
